import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1500 1500">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M689.000000,1501.000000 
	C459.333374,1501.000000 230.166748,1501.000000 1.000092,1501.000000 
	C1.000062,1001.000122 1.000062,501.000275 1.000031,1.000293 
	C500.999786,1.000196 1000.999573,1.000196 1500.999512,1.000098 
	C1500.999756,500.999664 1500.999756,1000.999329 1500.999878,1500.999512 
	C1230.500000,1501.000000 960.000000,1501.000000 689.000000,1501.000000 
M769.002563,866.997314 
	C769.002563,866.997314 768.993530,867.011902 769.501770,867.021851 
	C769.669739,866.999878 769.837769,866.977905 770.754944,866.981934 
	C771.503296,866.987549 772.251648,866.993164 773.152222,867.747620 
	C774.634338,868.134766 776.351929,869.212158 777.561157,868.799561 
	C786.926025,865.604248 796.679688,863.052856 805.374451,858.506897 
	C818.106506,851.850037 829.465820,843.098755 839.351624,832.338501 
	C854.746277,815.582397 863.636292,795.755249 868.821777,773.998291 
	C869.165161,772.557678 867.442688,770.624756 866.792542,768.105103 
	C866.913452,766.737854 867.034302,765.370605 867.920715,763.975586 
	C868.918945,762.856201 870.705505,761.786438 870.779602,760.608887 
	C871.108643,755.379761 871.207642,750.098816 870.787659,744.885071 
	C870.676208,743.501587 868.424133,742.290527 867.028320,740.185669 
	C866.907654,738.818848 866.787048,737.452026 867.480225,735.931213 
	C870.278625,731.711914 869.623840,729.237793 864.996826,726.505066 
	C865.003235,726.335449 865.009583,726.165771 865.663940,725.641357 
	C869.240540,722.341125 865.716187,719.404785 864.872009,716.609314 
	C862.785217,709.699158 860.956482,702.443176 857.341675,696.319336 
	C850.296570,684.384216 842.071777,673.293518 831.580017,663.820374 
	C819.068420,652.523438 805.615295,643.364990 789.230469,638.856445 
	C782.695862,637.058350 776.287415,634.755005 769.691040,633.256104 
	C767.633850,632.788513 764.253662,631.224243 762.037170,635.175232 
	C754.692688,635.142395 747.348267,635.109558 739.844604,634.285706 
	C738.864807,633.858398 737.822510,632.965698 736.916443,633.087463 
	C733.683899,633.521729 730.422180,634.052002 727.307922,634.996094 
	C717.949402,637.832947 708.318481,640.124939 699.435974,644.073914 
	C691.496948,647.603577 684.285278,652.866394 677.027466,657.770996 
	C674.898987,659.209167 673.708801,662.036011 671.780823,664.802673 
	C668.940552,667.568909 666.100342,670.335205 662.523071,673.088684 
	C661.048767,674.326294 659.387878,675.399536 658.130005,676.827698 
	C648.738770,687.491028 642.205994,699.832275 637.652100,713.187866 
	C636.489685,716.596741 636.696045,720.472229 636.166931,724.761353 
	C636.069946,725.174500 635.972900,725.587646 635.135437,726.114441 
	C631.798035,728.471924 632.381042,731.239197 634.205261,734.953430 
	C634.084839,736.303101 633.964478,737.652832 633.118164,739.163452 
	C632.531067,739.917419 631.460571,740.654175 631.432983,741.428040 
	C631.205078,747.825562 631.071594,754.231934 631.200867,760.629700 
	C631.217163,761.436951 632.921326,762.210083 633.968567,763.747559 
	C634.021912,764.757874 634.075256,765.768188 633.787048,767.533691 
	C633.233154,770.656433 630.925415,774.205139 635.972656,776.602539 
	C636.015015,777.011719 636.057312,777.420898 635.779663,778.543579 
	C635.667114,779.966980 635.146301,781.503784 635.505249,782.796265 
	C639.082336,795.674072 643.953735,808.003784 652.011658,818.811707 
	C653.275391,820.506592 655.413940,821.549255 657.107666,822.993713 
	C657.107666,822.993713 657.102356,822.885864 657.044006,823.649963 
	C657.847900,825.417542 658.288086,827.511719 659.525574,828.890015 
	C662.920593,832.671448 666.640259,836.162476 670.249756,839.750061 
	C685.335815,854.744385 704.117188,862.923462 724.129456,868.732361 
	C725.172729,869.035217 726.697083,867.680359 728.705017,867.196228 
	C730.080261,867.376221 731.455566,867.556213 733.227783,868.313782 
	C734.020996,868.221375 734.814148,868.128967 735.607361,868.036499 
	C734.733582,866.691040 733.859863,865.345520 732.880188,863.067810 
	C732.882874,856.474915 732.885559,849.881958 732.898499,842.500488 
	C731.969849,838.028015 727.995850,839.500977 725.247253,838.580872 
	C705.163635,831.858459 690.357971,819.194153 681.888184,799.617554 
	C679.168579,793.331543 675.770935,787.235657 676.764038,779.762695 
	C677.038208,777.699768 674.502014,775.263367 673.104614,772.072937 
	C673.147034,753.384338 673.189514,734.695740 674.041199,715.976501 
	C679.949402,715.651001 685.854065,715.209229 691.766724,715.033020 
	C697.582458,714.859558 699.383362,712.963928 699.000244,707.294922 
	C698.567688,700.894531 698.381042,694.477600 697.962891,688.076050 
	C697.850647,686.357361 697.285339,684.668396 696.867554,682.307800 
	C696.923706,681.872070 696.979858,681.436279 697.731628,680.921387 
	C698.925354,679.428650 700.297241,678.044006 701.281128,676.423828 
	C704.976807,670.338257 710.454041,668.369080 717.275085,668.973938 
	C723.017944,669.483093 726.208069,673.195984 729.094543,677.596863 
	C729.995361,678.970276 731.604980,679.878784 733.051636,681.926147 
	C733.056946,690.950378 733.062317,699.974609 732.358704,709.105103 
	C729.481140,713.348694 732.977478,714.709717 735.720154,714.803894 
	C746.148743,715.161926 756.599243,715.091187 767.035828,714.887207 
	C770.185181,714.825684 771.018005,712.314209 768.938721,709.069275 
	C769.009338,699.713074 769.079956,690.356812 769.833496,680.910400 
	C770.856506,679.903259 772.186218,679.064636 772.848389,677.859253 
	C775.442261,673.137512 779.096436,669.620850 784.508179,669.098511 
	C791.272827,668.445435 797.323853,669.998535 800.980164,676.707336 
	C801.744324,678.109436 803.597656,678.917908 805.102234,680.645630 
	C805.109924,681.086792 805.117615,681.527893 804.308716,682.146118 
	C803.872559,683.613831 803.085938,685.075317 803.059448,686.550354 
	C802.929016,693.825500 802.978760,701.104370 803.006775,708.381775 
	C803.028870,714.107971 803.901306,714.839966 809.647400,715.028137 
	C816.052734,715.237976 822.450989,715.666077 828.974426,716.929321 
	C828.902710,736.636902 828.830994,756.344543 828.153809,776.373535 
	C824.127502,779.808044 823.704529,784.173767 827.112122,787.695984 
	C827.021912,788.130371 826.931702,788.564697 826.144104,789.096130 
	C825.090576,790.442078 823.528992,791.632568 823.062073,793.158081 
	C818.454773,808.211060 809.255371,820.109497 796.473145,828.653381 
	C789.413025,833.372437 780.678284,835.552612 772.813416,839.129883 
	C771.273987,839.830200 770.263123,841.692383 768.988403,843.951782 
	C768.960938,850.633972 768.933411,857.316101 768.234802,864.120911 
	C767.825195,865.089600 767.415649,866.058289 766.716309,867.377869 
	C767.483215,867.249268 768.250183,867.120605 769.002563,866.997314 
M636.051880,657.009521 
	C636.051880,657.009521 636.080139,656.906311 636.037109,657.668457 
	C637.591858,661.761169 640.515015,663.231262 644.713745,661.797241 
	C645.108582,661.793152 645.503357,661.789124 646.331177,662.392151 
	C648.168945,662.096497 650.333557,662.313721 651.774536,661.395447 
	C654.534607,659.636536 656.805603,657.131409 659.417603,655.114746 
	C667.371399,648.973816 674.955444,642.205505 683.484619,636.992310 
	C697.118713,628.658875 711.882324,622.800598 727.987427,620.834534 
	C729.451355,620.655884 730.670471,618.471313 732.816345,617.060303 
	C734.195190,617.004639 735.574036,616.948914 737.419128,617.532471 
	C746.596008,617.685425 755.772827,617.849731 764.950317,617.948181 
	C765.298889,617.951965 765.654358,617.319946 766.758972,616.939026 
	C767.504944,617.031677 768.250916,617.124329 769.107727,617.908325 
	C771.358032,618.899475 773.563293,620.021423 775.880188,620.819214 
	C776.845886,621.151794 778.155579,620.476746 779.127136,620.802246 
	C784.994568,622.768066 790.758972,625.048950 796.651428,626.931396 
	C813.668091,632.367920 827.751892,642.685669 841.550293,653.530212 
	C843.998047,655.453979 846.643005,657.126648 849.691711,659.140076 
	C850.157654,659.684021 850.623657,660.227966 851.120361,661.527222 
	C855.930725,666.277527 860.702942,666.454529 865.439087,662.058350 
	C872.063171,655.909424 873.193665,649.159058 867.745911,643.433533 
	C861.725220,637.105896 855.538391,630.761963 848.598450,625.517639 
	C831.554199,612.637817 812.594543,603.299316 791.944397,597.617249 
	C790.836060,597.312195 789.293884,598.583740 787.293274,599.099121 
	C786.864685,599.001160 786.436157,598.903137 785.980286,598.074646 
	C783.356384,594.174194 781.607300,593.943359 777.239746,597.055115 
	C776.494446,596.958618 775.749146,596.862122 774.887207,596.056030 
	C771.712830,592.100952 765.796265,591.662720 761.030396,595.142517 
	C753.700012,595.128540 746.369568,595.114563 738.665955,594.396301 
	C733.212585,591.524292 728.610474,592.469238 726.264282,596.963196 
	C725.519714,597.016724 724.775085,597.070190 723.884277,596.362305 
	C722.591919,595.916626 720.945251,594.735413 720.070374,595.156738 
	C716.821472,596.721313 713.217773,596.495239 710.055115,597.432922 
	C700.804199,600.175476 691.756836,603.766357 682.900330,607.642944 
	C666.007446,615.037048 651.933777,626.588257 638.585022,639.047119 
	C637.299561,640.246887 636.889099,642.384216 636.038757,643.990784 
	C636.038757,643.990784 636.102356,644.069763 635.414368,644.108093 
	C634.979553,645.800964 634.544739,647.493835 634.097412,649.840576 
	C634.103455,650.494446 634.109497,651.148254 633.831360,652.447144 
	C634.591858,653.938660 635.352356,655.430115 636.051880,657.009521 
M742.283875,867.993530 
	C741.554688,868.060730 740.825500,868.127930 740.096313,868.195129 
	C740.112610,868.350037 740.128845,868.504883 740.145142,868.659729 
	C747.064941,868.659729 753.984741,868.659729 760.904602,868.659729 
	C760.906677,868.500854 760.908813,868.342041 760.910889,868.183167 
	C760.275574,868.115967 759.640198,868.048828 758.817749,867.192505 
	C753.856079,866.905334 748.895569,866.589417 743.930115,866.403687 
	C743.638550,866.392822 743.307739,867.432068 742.283875,867.993530 
M1011.981140,941.442139 
	C1011.723450,940.977356 1011.465759,940.512634 1011.072998,939.292725 
	C1011.067627,938.527161 1011.062317,937.761597 1011.797913,936.833313 
	C1013.825439,933.848572 1013.339966,931.308289 1011.073853,928.478333 
	C1004.106873,919.777954 993.902771,918.695435 984.942383,920.765442 
	C976.926086,922.617371 970.358704,922.054077 962.981262,918.932251 
	C963.265015,917.592590 963.994202,916.096375 963.740723,914.791016 
	C963.494446,913.522888 962.325500,912.364563 961.337036,911.384766 
	C961.188171,911.237244 959.815002,912.324951 958.117371,912.979248 
	C955.410095,912.939148 952.702759,912.899048 949.868896,912.193176 
	C947.261841,911.333496 945.004028,912.450134 944.148132,915.029968 
	C942.945801,918.654175 941.748901,922.280151 940.254883,926.796631 
	C933.956787,921.840088 927.429504,920.446716 920.234253,920.905396 
	C913.370544,921.342896 906.331177,920.646179 901.930054,927.592468 
	C901.624756,927.498047 901.184204,927.499695 901.037415,927.292847 
	C900.369812,926.352112 899.709595,925.391479 899.182556,924.368042 
	C895.409912,917.041931 889.323120,912.284180 881.760498,909.624512 
	C879.894653,908.968323 877.275940,910.452698 874.109375,911.020203 
	C871.718750,910.939392 869.328125,910.858582 866.494019,910.345886 
	C865.350281,910.489563 864.206604,910.633179 862.130188,910.897888 
	C857.415588,910.923157 852.700989,910.948486 847.892883,910.323975 
	C847.035217,910.368347 845.451355,910.308655 845.427917,910.472412 
	C845.017883,913.335144 844.492615,916.238037 844.612610,919.106201 
	C844.778503,923.071960 846.100952,927.115784 843.130249,930.880920 
	C841.388855,929.042236 840.157715,927.433960 838.630859,926.188110 
	C834.126099,922.512268 829.636353,919.495300 823.042297,920.821899 
	C815.024231,922.434998 814.423096,921.528870 813.997009,913.119507 
	C813.669678,906.659424 811.938049,905.012512 805.457764,905.000305 
	C804.291504,904.998108 803.125305,904.994751 801.959045,905.000916 
	C795.403259,905.035400 793.617920,907.293457 794.844788,913.994324 
	C795.909607,919.810608 794.637268,920.987793 788.825439,920.199341 
	C786.398560,919.870056 783.655273,919.715515 781.455994,920.543823 
	C779.668884,921.216797 777.386292,923.363342 777.213501,925.049988 
	C776.471741,932.289612 776.235535,939.599854 776.199707,946.886292 
	C776.176453,951.617798 774.000305,953.392212 769.674316,951.573120 
	C768.213074,950.958740 766.678894,950.517883 765.024902,949.071838 
	C765.049500,941.045898 765.074036,933.020020 765.784363,924.866943 
	C768.062561,922.790955 766.435669,921.869751 764.633240,921.036438 
	C755.811462,916.958008 749.062744,921.223755 748.958740,930.823486 
	C748.933167,933.180481 748.394897,935.531982 748.221375,936.882202 
	C739.281250,933.323303 731.271912,930.134949 721.771606,926.353088 
	C724.125000,925.313293 725.609741,924.026672 726.781128,924.267090 
	C729.480103,924.821045 731.965210,926.360413 734.653320,927.040833 
	C737.162781,927.675964 740.609802,928.888428 742.253967,927.781738 
	C745.820007,925.381042 749.792358,922.311340 748.870850,916.978149 
	C748.628235,915.573730 747.392822,913.882507 746.136536,913.197693 
	C742.393921,911.157776 738.409424,909.563232 734.528381,907.775208 
	C726.884583,904.253479 711.950439,906.928162 707.910706,914.192383 
	C704.567200,920.204834 700.750305,921.060486 695.115723,920.015991 
	C694.472473,919.896790 693.788208,919.999329 693.008118,919.114014 
	C692.924438,917.209900 692.840759,915.305847 692.732849,912.851074 
	C688.830200,912.851074 685.412598,912.851074 681.877625,912.213074 
	C674.768494,910.669128 678.531494,917.578857 676.456238,919.357971 
	C670.229980,920.986328 664.397034,923.450867 658.440552,920.019653 
	C658.314453,919.947021 658.111633,920.017151 657.946350,920.001648 
	C651.730713,919.418335 646.941345,921.305359 645.441162,927.953857 
	C642.500000,925.958801 640.258179,924.317871 637.902100,922.861877 
	C630.076355,918.025696 621.751160,920.910217 613.715149,921.789001 
	C607.868286,922.428345 606.079468,926.063171 607.415161,931.762207 
	C607.717407,933.051575 607.963684,934.362488 608.381104,935.615051 
	C610.362000,941.559143 609.753113,942.446533 603.067322,942.109009 
	C602.945068,939.431213 602.822876,936.753418 603.466370,934.025391 
	C607.896912,933.375610 605.009338,930.555603 604.437073,929.335693 
	C600.850464,921.690002 587.526367,917.509338 579.862793,921.933167 
	C576.106140,924.101562 573.342834,924.226501 569.891052,921.713013 
	C568.207703,920.487061 565.693115,919.915833 564.145630,923.171936 
	C563.096497,923.105042 562.047424,923.038208 560.837585,922.221130 
	C553.082886,921.810547 545.329956,921.350464 537.570557,921.070190 
	C536.839417,921.043762 535.402649,922.166199 535.417847,922.724731 
	C535.557678,927.865601 535.945557,932.999756 536.212830,938.759460 
	C536.158691,939.172546 536.104553,939.585693 535.308228,939.992310 
	C534.395447,939.669800 533.208923,939.607971 532.613770,938.983032 
	C528.231873,934.381531 522.979248,931.758911 516.693298,930.615601 
	C513.659363,930.063782 510.872040,928.156616 506.975220,926.417786 
	C512.647522,923.733032 516.535889,925.895569 520.473999,927.742188 
	C525.304810,930.007385 529.534729,927.876709 532.533020,921.703369 
	C534.242310,918.184021 532.721130,913.409607 528.828735,911.597351 
	C524.968994,909.800354 520.883667,908.457458 516.833862,907.105347 
	C507.654694,904.040466 494.821259,908.957703 490.170868,917.474548 
	C489.524506,918.658264 490.210327,920.569397 490.160187,923.021301 
	C490.176331,925.978149 490.192474,928.934937 489.592316,932.354370 
	C487.915985,936.470032 490.228912,939.385498 493.293396,940.974426 
	C498.059357,943.445740 503.216949,945.231506 508.334259,946.928894 
	C511.314209,947.917297 514.198181,948.559326 514.904480,952.451721 
	C509.079498,953.977905 504.581848,951.642090 500.122467,949.054199 
	C495.527618,946.387634 490.711121,948.493530 487.185699,954.655396 
	C485.318665,957.918701 486.650787,962.744995 490.734985,964.727905 
	C495.084137,966.839478 499.993378,967.784180 504.383423,969.828247 
	C509.026825,971.990356 513.430298,971.990845 518.324646,970.882202 
	C523.531311,969.702820 527.982056,967.819214 531.224976,963.416809 
	C531.779602,962.664001 533.073486,962.455811 534.517761,961.758423 
	C534.760193,963.791260 534.480164,965.562439 535.231995,966.540039 
	C535.925232,967.441406 537.726440,967.490662 539.929321,967.983643 
	C542.604980,967.958801 545.280579,967.934021 548.368225,968.455505 
	C553.060791,968.462830 553.885803,965.166748 553.960266,961.569275 
	C554.097351,954.939575 553.814941,948.295349 554.151978,941.679871 
	C554.227783,940.191162 556.130554,938.795471 557.192322,937.356995 
	C558.125305,938.757935 559.323486,940.058350 559.904785,941.592468 
	C560.344666,942.753357 559.998840,944.212646 559.999756,945.539124 
	C560.003662,951.508423 559.868164,957.482056 560.056335,963.445496 
	C560.191284,967.723572 561.634033,968.827881 565.927612,967.985779 
	C568.591858,967.974915 571.256165,967.964050 574.588379,968.241821 
	C578.154236,969.404236 579.019348,967.483276 579.007141,964.438416 
	C578.980530,957.788086 579.036438,951.137329 578.985474,944.487305 
	C578.961365,941.339539 579.860718,938.427002 583.258362,938.379944 
	C584.519653,938.362427 586.749573,941.194641 586.940063,942.905273 
	C587.651855,949.297729 587.579468,955.773621 588.084961,962.195801 
	C588.213318,963.826172 589.390381,965.374084 590.594421,966.936523 
	C590.762817,966.914429 590.931152,966.892273 591.794006,967.297729 
	C595.862061,967.868713 600.256165,970.058838 603.902649,965.013184 
	C604.532593,965.172913 605.327637,965.140991 605.768127,965.520569 
	C614.628845,973.156067 624.176514,972.353882 634.930054,967.990784 
	C637.604675,967.963440 640.279297,967.936035 643.372192,968.464233 
	C645.929810,968.279846 648.487427,968.095459 651.942749,967.982971 
	C654.947266,967.958496 657.951843,967.934021 661.374939,968.465210 
	C662.511658,968.030212 663.648376,967.595215 664.785095,967.160217 
	C664.190918,966.440125 663.596680,965.720032 662.895874,964.253784 
	C662.929993,963.502502 662.964111,962.751282 663.692383,961.879272 
	C665.676453,960.190430 665.772888,958.838867 662.894226,957.095886 
	C662.959656,954.063416 663.025146,951.030945 663.768494,947.920410 
	C666.045044,945.595642 668.156006,943.066223 670.668335,941.032715 
	C671.916382,940.022583 673.988037,939.395081 675.514526,939.645752 
	C676.222168,939.761902 676.836853,942.368958 676.934265,943.882751 
	C677.146851,947.184509 676.689575,950.552429 677.140869,953.805298 
	C677.348206,955.299377 679.050903,956.585999 680.483582,957.991760 
	C680.483582,957.991760 680.889404,958.042419 680.957947,958.694092 
	C680.954346,959.130188 680.950806,959.566345 680.229919,960.146790 
	C680.144165,961.867249 679.290588,964.209106 680.098022,965.206665 
	C685.126648,971.419495 695.193909,973.235779 702.033875,969.322144 
	C702.971313,968.785828 703.202942,967.015991 703.700806,965.786621 
	C703.700806,965.786621 703.693176,965.719116 704.580505,965.908936 
	C709.161011,967.037842 713.999512,967.568604 718.272339,969.411133 
	C729.742798,974.357483 738.990417,970.717712 747.521484,963.003906 
	C748.332825,962.270386 749.302795,961.712280 749.717285,961.416443 
	C753.072083,964.194275 755.916931,966.869385 759.075073,969.101013 
	C766.631287,974.440430 773.751038,969.220764 781.914978,967.981384 
	C784.580750,967.975220 787.246460,967.969055 790.574036,968.243713 
	C793.398254,968.131958 796.222473,968.020203 799.931030,967.985168 
	C802.592102,967.973083 805.253235,967.961060 808.655334,968.277954 
	C812.280029,969.187744 815.873230,970.268677 819.541321,970.942078 
	C821.693359,971.337158 824.013855,971.399414 826.173157,971.063049 
	C833.265381,969.958435 838.793274,966.170959 843.019043,959.159668 
	C843.879089,962.585022 844.127075,964.868408 845.044250,966.840576 
	C845.869507,968.615051 847.371399,970.823792 849.925171,967.061157 
	C858.282959,967.034790 866.640747,967.008423 875.150146,967.735413 
	C876.785706,968.102905 878.766235,969.244934 879.994507,968.697876 
	C884.303101,966.779114 888.423218,964.395386 892.468933,961.947021 
	C894.321472,960.826050 895.834351,959.143738 896.372559,958.682739 
	C900.648315,962.575623 904.038513,966.048462 907.820435,969.023254 
	C915.122009,974.766296 922.087463,970.327087 929.964294,967.960510 
	C931.610962,967.976135 933.267944,967.878906 934.902344,968.028625 
	C939.588196,968.457825 941.291565,966.491821 941.080933,961.741699 
	C940.775085,954.843201 941.095215,947.916931 941.954468,941.039917 
	C942.635559,942.135254 943.785950,943.182312 943.904480,944.335510 
	C944.192078,947.131348 943.901245,949.980713 944.019836,952.801025 
	C944.287292,959.162964 945.925598,965.147827 951.663452,968.627319 
	C958.213623,972.599365 965.008362,972.541077 971.579285,968.077576 
	C972.444702,967.489746 974.334595,967.269592 975.071472,967.807922 
	C983.424683,973.910278 991.712158,971.507080 1000.924194,967.982849 
	C1004.263794,967.644714 1007.603394,967.306519 1011.473755,966.992615 
	C1011.650818,966.995117 1011.827820,966.997559 1012.797546,966.817871 
	C1013.171631,966.315979 1013.864990,965.818420 1013.871704,965.311707 
	C1013.964417,958.333801 1014.026550,951.353516 1013.897278,944.377441 
	C1013.882324,943.572815 1012.672791,942.790344 1011.981140,941.442139 
M728.437195,577.556091 
	C735.348389,580.244507 741.904053,579.952393 748.808044,575.089905 
	C750.871704,575.142761 752.935303,575.195557 755.124573,575.958374 
	C758.326355,578.863464 769.915100,580.341309 773.511230,578.239014 
	C773.833008,578.050903 773.859619,577.357971 774.822388,576.871094 
	C775.881226,576.972229 776.940002,577.073364 778.102112,577.880432 
	C781.143982,581.869995 783.310486,582.154663 786.686707,578.912781 
	C787.122864,578.997498 787.558960,579.082153 788.017822,579.938171 
	C789.080322,580.952515 789.970642,582.473145 791.232178,582.901978 
	C802.459473,586.718811 814.280334,589.228088 824.911682,594.264343 
	C844.646545,603.613281 862.122375,616.540100 877.689514,632.008545 
	C880.885559,635.184387 885.022827,637.448425 890.207031,636.121521 
	C901.289490,633.284851 902.031860,619.178833 895.846741,613.346191 
	C889.803162,607.646973 884.301208,601.197388 877.615967,596.392944 
	C865.262695,587.515259 852.916687,578.120605 839.275208,571.678101 
	C824.193970,564.555664 807.686096,560.431458 791.717712,555.253845 
	C790.467590,554.848511 788.575012,556.424683 786.312439,557.088745 
	C785.875244,557.007019 785.438110,556.925293 784.896790,556.125305 
	C781.831421,552.114685 777.861633,551.696350 774.171509,555.106140 
	C773.099121,555.000305 772.026672,554.894470 770.603149,554.203613 
	C768.304138,553.136108 766.039673,551.246216 763.699890,551.147827 
	C754.973328,550.780518 746.212646,550.786499 737.487610,551.170776 
	C735.608643,551.253540 733.824829,553.496704 731.181458,554.909546 
	C729.795593,554.971741 728.409668,555.033997 726.863708,554.317566 
	C721.999390,551.746033 719.279236,552.445923 716.358398,557.020874 
	C715.918762,557.046936 715.479187,557.072937 714.691101,556.422363 
	C711.616516,553.001953 708.916016,556.165955 706.159058,556.992676 
	C694.009277,560.635925 681.432800,563.325745 669.860352,568.326538 
	C646.037231,578.621460 624.782227,593.082764 607.238464,612.454712 
	C604.827576,615.116821 603.082886,618.850586 602.257019,622.375732 
	C600.835693,628.442871 608.681519,637.850647 614.527039,636.611816 
	C619.155212,635.630920 624.177429,633.104675 627.454712,629.736877 
	C642.622070,614.150757 660.189026,602.156860 679.832153,593.152710 
	C689.815796,588.576294 699.885315,584.043030 711.067688,582.915833 
	C714.341064,582.585876 717.643555,581.856689 720.738281,580.761230 
	C722.080627,580.286133 722.931274,578.421875 724.786194,577.029785 
	C725.847107,576.996521 726.907959,576.963257 728.437195,577.556091 
M790.897949,518.145813 
	C787.868042,514.224487 781.278076,513.621887 777.250183,517.035217 
	C776.500000,516.971558 775.749756,516.907898 774.892517,516.126160 
	C772.972778,515.085449 771.072388,513.187927 769.130066,513.143860 
	C756.972229,512.868408 744.800415,512.840454 732.645691,513.179138 
	C730.731384,513.232544 728.880493,515.565186 726.222595,516.972351 
	C725.173340,516.998657 724.124146,517.024963 722.446350,516.592896 
	C717.669617,513.459106 713.255188,514.341248 710.343689,519.034058 
	C709.896301,519.031311 709.448853,519.028625 708.858521,518.285095 
	C705.077209,515.615540 702.506287,516.625305 700.343079,521.020935 
	C699.895874,521.022705 699.448669,521.024414 698.861328,520.283691 
	C696.182739,520.480469 693.447693,520.396057 690.834656,520.919861 
	C674.368591,524.220825 658.803101,530.421936 644.152527,538.238037 
	C630.214478,545.674011 617.148865,554.819946 604.084045,563.774902 
	C599.806030,566.707092 596.733032,571.397583 592.861511,575.763977 
	C592.301575,576.201538 591.741638,576.639160 590.422668,577.035278 
	C585.340332,582.027283 579.713257,586.596924 575.388428,592.176086 
	C572.952820,595.318115 575.615234,603.160400 578.970459,605.246948 
	C585.596313,609.367371 588.159607,610.665161 593.583191,607.079346 
	C596.545654,605.120728 598.826233,602.163147 601.627014,599.921814 
	C612.175781,591.480225 622.618713,582.887878 633.457092,574.830505 
	C653.629456,559.834106 676.365540,550.412476 700.778320,544.766907 
	C702.062378,544.469910 702.938660,542.409851 704.648193,541.003845 
	C705.089539,540.979187 705.530945,540.954529 706.325378,541.602295 
	C710.996643,544.112854 712.784546,543.599243 715.639771,538.938843 
	C716.078918,538.903809 716.518066,538.868774 717.119995,539.630859 
	C721.931702,542.363831 725.435608,541.502502 727.820496,537.036865 
	C729.203308,537.001099 730.586121,536.965393 732.439514,537.557800 
	C733.812256,538.036194 735.179932,538.911560 736.558533,538.929199 
	C746.117798,539.051086 755.681213,539.089844 765.238281,538.902527 
	C766.848267,538.870972 768.434326,537.616577 770.860657,536.865051 
	C772.238647,536.974304 773.616638,537.083496 775.023926,537.946350 
	C776.665833,538.963135 778.189148,540.434082 779.974792,540.900513 
	C783.502869,541.822021 787.161316,542.465820 790.796021,542.660034 
	C792.501953,542.751160 794.277832,541.532654 796.689880,540.901489 
	C797.126343,540.985352 797.562744,541.069214 798.102173,541.870056 
	C799.129089,542.877808 799.988770,544.427124 801.210327,544.804199 
	C808.370178,547.014404 815.685791,548.729309 822.816467,551.022583 
	C840.642639,556.755554 857.006165,565.597290 871.928589,576.695374 
	C883.667419,585.425720 894.148438,595.827759 905.624207,604.938171 
	C913.107971,610.879456 917.343994,610.780945 925.192627,602.266907 
	C928.743164,598.415283 927.451233,589.398071 923.040894,585.629089 
	C918.129089,581.431702 913.118347,577.330078 908.443604,572.879028 
	C893.897827,559.029419 877.386169,547.898438 859.537048,539.009521 
	C847.275269,532.903137 834.292419,528.161499 821.405396,523.426025 
	C813.905884,520.670288 806.005493,518.922058 798.180298,517.197754 
	C796.342102,516.792725 794.078918,518.316833 791.500305,518.986328 
	C791.333618,518.949646 791.166870,518.913025 790.897949,518.145813 
z"/>
<path fill="#CB6EE5" opacity="1.000000" stroke="none" 
	d="
M826.841553,788.999084 
	C826.931702,788.564697 827.021912,788.130371 827.362610,787.066772 
	C827.995178,782.975769 828.377258,779.513916 828.759338,776.052124 
	C828.830994,756.344543 828.902710,736.636902 828.977295,716.035156 
	C829.479492,711.784485 827.274963,711.870056 824.967346,711.888428 
	C818.726562,711.938049 812.485168,711.905457 805.867615,711.905457 
	C805.867615,707.639709 805.950806,704.172791 805.849365,700.711304 
	C805.666199,694.462158 805.372681,688.216309 805.125244,681.969055 
	C805.117615,681.527893 805.109924,681.086792 805.047363,679.951782 
	C802.046997,669.835388 795.164612,665.370667 784.766541,666.137268 
	C776.672180,666.734009 770.296143,672.802673 769.150574,681.000610 
	C769.079956,690.356812 769.009338,699.713074 768.858643,709.915405 
	C768.432190,711.085266 768.088501,711.688782 767.739197,711.692017 
	C757.017578,711.792358 746.294678,711.899109 735.575134,711.759155 
	C734.727356,711.748108 733.902832,709.960754 733.067627,708.998840 
	C733.062317,699.974609 733.056946,690.950378 733.011475,681.089539 
	C730.243896,670.170227 723.715576,665.535400 713.067322,666.121887 
	C704.488281,666.594421 698.151306,672.475769 697.036072,681.000549 
	C696.979858,681.436279 696.923706,681.872070 696.667114,682.976196 
	C696.387085,691.277039 696.008972,698.922363 696.339966,706.536865 
	C696.538574,711.106995 694.942566,712.307251 690.665771,711.983826 
	C686.041504,711.634338 681.363586,712.058411 676.721924,711.853943 
	C673.454163,711.709961 672.533325,713.057312 673.231934,716.007141 
	C673.189514,734.695740 673.147034,753.384338 673.109863,772.967163 
	C673.501282,787.108765 676.960815,799.483948 684.183105,810.618713 
	C695.727478,828.416870 712.106995,839.176270 732.888245,843.289062 
	C732.885559,849.881958 732.882874,856.474915 732.877441,863.905884 
	C732.860107,865.741394 732.845459,866.738831 732.830811,867.736206 
	C731.455566,867.556213 730.080261,867.376221 727.955688,867.081238 
	C726.451782,866.638184 725.726990,866.168030 724.938354,866.002930 
	C703.587341,861.531433 685.224182,851.507324 669.748230,836.198486 
	C665.399048,831.896240 661.310486,827.330688 657.102356,822.885864 
	C657.102356,822.885864 657.107666,822.993713 657.098633,822.592102 
	C654.138977,817.479553 650.619995,813.024658 648.358459,808.003296 
	C643.903320,798.111755 640.145752,787.905945 636.099670,777.830078 
	C636.057312,777.420898 636.015015,777.011719 635.990845,775.898987 
	C635.382263,772.389771 634.755493,769.584167 634.128662,766.778503 
	C634.075256,765.768188 634.021912,764.757874 633.967346,762.912231 
	C633.925415,754.385437 633.884705,746.693970 633.844055,739.002502 
	C633.964478,737.652832 634.084839,736.303101 634.518066,734.278381 
	C635.179260,731.069153 635.527588,728.534973 635.875916,726.000793 
	C635.972900,725.587646 636.069946,725.174500 636.496826,724.203918 
	C640.881775,713.869812 644.362488,703.798950 649.173157,694.409485 
	C653.031860,686.878052 658.503967,680.173157 663.260132,673.101440 
	C666.100342,670.335205 668.940552,667.568909 672.283569,664.456909 
	C684.203430,654.399536 696.978210,646.848816 711.133362,642.126770 
	C720.498596,639.002625 730.362915,637.374512 740.003784,635.076660 
	C747.348267,635.109558 754.692688,635.142395 762.817444,635.381531 
	C798.780212,640.141235 826.749573,656.692261 847.177307,685.808228 
	C855.758240,698.038757 860.859863,711.805664 865.015991,725.996155 
	C865.009583,726.165771 865.003235,726.335449 865.013733,727.151611 
	C865.575867,730.560547 866.121094,733.322876 866.666382,736.085205 
	C866.787048,737.452026 866.907654,738.818848 867.030823,741.034485 
	C867.073975,749.256653 867.114563,756.630005 867.155212,764.003357 
	C867.034302,765.370605 866.913452,766.737854 866.497437,768.812866 
	C866.099731,770.678589 866.121338,771.863525 865.876404,772.990540 
	C857.871582,809.817383 837.991516,838.026245 804.327148,855.467773 
	C794.524658,860.546448 783.476929,863.221802 773.000000,866.998840 
	C772.251648,866.993164 771.503296,866.987549 770.368164,866.504761 
	C769.998474,858.371582 770.015503,850.715454 770.407104,842.973267 
	C771.905396,842.594971 773.034485,842.321533 774.152222,842.007996 
	C801.713135,834.277100 819.592468,816.920532 826.841553,788.999084 
z"/>
<path fill="#CA6EE4" opacity="1.000000" stroke="none" 
	d="
M723.074890,517.051208 
	C724.124146,517.024963 725.173340,516.998657 727.042236,516.968933 
	C734.598816,516.574951 741.335632,516.184448 748.496216,515.861328 
	C750.920654,515.883972 752.921326,515.839233 755.388733,515.854858 
	C762.236816,516.224854 768.618225,516.534485 774.999573,516.844177 
	C775.749756,516.907898 776.500000,516.971558 778.018433,517.113342 
	C782.857910,517.753113 786.929016,518.314758 791.000183,518.876343 
	C791.166870,518.913025 791.333618,518.949646 792.095337,519.177490 
	C814.433350,523.528564 835.362732,530.143921 855.168274,540.128235 
	C879.841125,552.566345 902.023376,568.383179 921.073975,588.506165 
	C924.232910,591.842957 924.718872,595.575134 923.514465,599.538635 
	C922.349976,603.370728 919.250366,605.195984 915.316711,605.843689 
	C911.043884,606.547119 908.054443,604.473877 905.258911,601.724609 
	C899.458862,596.020569 894.184509,589.603149 887.713806,584.799255 
	C876.247925,576.286865 864.452209,568.055603 852.062378,560.990723 
	C835.251831,551.405212 817.174194,544.620300 797.999207,541.153015 
	C797.562744,541.069214 797.126343,540.985352 796.065063,540.660889 
	C788.625061,539.344482 781.809814,538.268616 774.994629,537.192749 
	C773.616638,537.083496 772.238647,536.974304 770.109863,536.658447 
	C761.948181,536.284973 754.536255,535.957153 747.126831,536.007202 
	C742.072388,536.041443 737.021423,536.603516 731.968933,536.929626 
	C730.586121,536.965393 729.203308,537.001099 727.011169,537.071594 
	C723.120300,537.682129 720.038757,538.257935 716.957214,538.833740 
	C716.518066,538.868774 716.078918,538.903809 714.922485,538.992554 
	C711.460876,539.674194 708.716614,540.302002 705.972290,540.929810 
	C705.530945,540.954529 705.089539,540.979187 703.933594,541.070190 
	C696.654602,542.890198 689.988281,544.333435 683.542969,546.449524 
	C649.668823,557.570740 620.637329,576.444763 595.692566,601.786682 
	C590.491516,607.070557 584.824585,607.712708 580.447388,603.667786 
	C575.696594,599.277649 575.935364,592.853943 581.111389,587.423950 
	C584.432007,583.940430 587.822449,580.523499 591.181641,577.076721 
	C591.741638,576.639160 592.301575,576.201538 593.329041,575.451782 
	C599.378296,570.805786 605.009705,566.534302 610.532593,562.126831 
	C627.685547,548.437927 646.964172,538.558289 667.402649,530.961304 
	C677.738525,527.119507 688.456360,524.305115 699.001465,521.026184 
	C699.448669,521.024414 699.895874,521.022705 701.051697,520.975464 
	C704.174011,520.295349 706.587769,519.660645 709.001465,519.025940 
	C709.448853,519.028625 709.896301,519.031311 711.081177,518.980652 
	C715.570740,518.301941 719.322815,517.676575 723.074890,517.051208 
z"/>
<path fill="#CA6EE4" opacity="1.000000" stroke="none" 
	d="
M777.998840,577.174438 
	C776.940002,577.073364 775.881226,576.972229 774.095703,576.645874 
	C767.245728,576.029907 761.122314,575.639160 754.998962,575.248352 
	C752.935303,575.195557 750.871704,575.142761 747.927734,575.091431 
	C740.687927,575.705261 734.328369,576.317627 727.968872,576.929993 
	C726.907959,576.963257 725.847107,576.996521 723.994385,577.068970 
	C704.949341,580.334167 687.336670,585.527283 670.823730,594.117981 
	C652.823303,603.482422 636.892639,615.624634 622.550049,629.925171 
	C617.295532,635.164368 610.973633,635.215576 606.662354,630.086670 
	C603.411682,626.219543 602.816589,621.741272 606.054260,617.899475 
	C610.393372,612.750671 615.300110,608.053772 620.186279,603.397095 
	C635.747314,588.567017 654.180664,578.066467 673.872070,570.106323 
	C687.173584,564.729126 701.288513,561.364075 715.039551,557.098999 
	C715.479187,557.072937 715.918762,557.046936 717.081970,556.971558 
	C720.878296,556.313599 723.951050,555.704895 727.023804,555.096191 
	C728.409668,555.033997 729.795593,554.971741 732.016968,554.891846 
	C737.962646,554.584961 743.073914,554.044250 748.182800,554.065918 
	C755.774170,554.098022 763.363892,554.528015 770.954285,554.788635 
	C772.026672,554.894470 773.099121,555.000305 774.878723,555.330688 
	C778.724243,555.984680 781.862610,556.414124 785.000916,556.843567 
	C785.438110,556.925293 785.875244,557.007019 786.933838,557.328491 
	C809.166382,561.604553 829.555542,569.088501 848.662720,579.881165 
	C865.575378,589.434204 880.738770,601.329956 894.010010,615.565552 
	C898.384766,620.258118 898.289185,626.281555 893.834045,630.658020 
	C889.318481,635.093872 883.636230,635.036621 878.952820,630.437378 
	C874.811401,626.370239 870.980164,621.949280 866.580566,618.189392 
	C843.673401,598.612793 817.906982,584.687927 787.995117,579.166870 
	C787.558960,579.082153 787.122864,578.997498 786.074097,578.675537 
	C782.973938,578.017090 780.486389,577.595825 777.998840,577.174438 
z"/>
<path fill="#CA6EE4" opacity="1.000000" stroke="none" 
	d="
M739.039185,595.100708 
	C746.369568,595.114563 753.700012,595.128540 761.824097,595.352905 
	C766.746460,595.964050 770.875122,596.364868 775.003845,596.765625 
	C775.749146,596.862122 776.494446,596.958618 777.899902,597.278442 
	C781.042542,597.936218 783.525024,598.370667 786.007568,598.805176 
	C786.436157,598.903137 786.864685,599.001160 787.897095,599.353516 
	C795.294189,601.780334 802.235474,603.577942 808.853271,606.194153 
	C830.344543,614.689941 849.087769,627.356140 864.877441,644.284363 
	C870.508484,650.321411 868.901978,657.410217 861.773315,661.640930 
	C857.596008,664.119995 854.565186,661.891724 851.089600,660.771912 
	C850.623657,660.227966 850.157654,659.684021 849.278687,658.729614 
	C844.650330,654.149841 840.748108,649.594299 836.165222,645.878235 
	C816.534668,629.960815 794.146362,620.302429 768.996887,617.217041 
	C768.250916,617.124329 767.504944,617.031677 766.054199,616.714233 
	C755.883911,616.624023 746.418396,616.758606 736.952881,616.893250 
	C735.574036,616.948914 734.195190,617.004639 732.005066,617.089233 
	C729.569336,617.415222 727.941406,617.694824 726.320862,618.011902 
	C702.489197,622.675537 681.041931,632.209778 662.782593,648.560486 
	C657.468384,653.319275 651.543274,657.395813 645.898193,661.785034 
	C645.503357,661.789124 645.108582,661.793152 644.205505,661.504883 
	C641.158264,659.777100 638.619202,658.341675 636.080139,656.906250 
	C636.080139,656.906311 636.051880,657.009521 636.038330,656.613098 
	C635.388306,654.745178 634.751953,653.273621 634.115540,651.802124 
	C634.109497,651.148254 634.103455,650.494446 634.391724,649.302551 
	C635.158203,647.199585 635.630310,645.634705 636.102417,644.069763 
	C636.102356,644.069763 636.038757,643.990784 636.364807,643.904297 
	C638.832886,642.001709 641.075623,640.290649 643.100708,638.352295 
	C656.817810,625.222656 672.295288,614.609497 689.945984,607.702881 
	C700.995544,603.379272 712.648376,600.597412 724.030518,597.123657 
	C724.775085,597.070190 725.519714,597.016724 727.039307,596.920288 
	C731.555969,596.285095 735.297546,595.692871 739.039185,595.100708 
z"/>
<path fill="#CA72E2" opacity="1.000000" stroke="none" 
	d="
M573.920410,967.953247 
	C571.256165,967.964050 568.591858,967.974915 565.303894,967.577393 
	C564.457458,965.391174 564.081726,963.616943 564.038757,961.834778 
	C563.862915,954.536194 563.788391,947.235107 563.673584,939.708862 
	C563.548340,939.187866 563.342529,938.972900 562.898315,938.523926 
	C559.478943,935.093933 556.058350,933.142212 551.604126,936.955566 
	C550.907104,938.436890 550.497742,939.717834 550.023315,941.329285 
	C549.971252,942.107300 549.984314,942.554871 549.941040,943.468140 
	C549.918518,950.954956 549.952271,957.976074 549.918762,965.301392 
	C549.219727,966.373352 548.588013,967.141296 547.956299,967.909180 
	C545.280579,967.934021 542.604980,967.958801 539.272949,967.635742 
	C538.182617,966.813049 537.382446,966.347595 537.370117,965.862122 
	C537.208618,959.481995 537.238770,953.096375 537.036499,946.718079 
	C536.965332,944.470093 536.393616,942.238098 536.050415,939.998779 
	C536.104553,939.585693 536.158691,939.172546 536.535095,938.141052 
	C537.068542,933.114441 537.279724,928.706299 537.486633,924.386414 
	C547.192444,923.382385 548.197510,923.553467 550.925232,926.183411 
	C554.471375,925.052612 557.734863,924.011963 560.998291,922.971313 
	C562.047424,923.038208 563.096497,923.105042 564.790527,923.457947 
	C566.828369,924.209167 568.530029,924.317566 569.557068,925.205872 
	C572.734192,927.953674 575.296143,927.872498 579.086548,925.650574 
	C588.930603,919.879761 598.519104,923.580994 602.700684,934.075623 
	C602.822876,936.753418 602.945068,939.431213 603.064148,942.966187 
	C603.095520,945.212402 603.130127,946.601440 603.100891,948.450928 
	C603.080078,952.944092 603.123108,956.976807 603.113159,961.406982 
	C603.103760,962.870544 603.147400,963.936584 603.191040,965.002625 
	C600.256165,970.058838 595.862061,967.868713 591.423218,966.627686 
	C590.797791,957.571411 590.543030,949.185303 590.221924,940.437622 
	C588.740173,936.076050 585.426880,934.265625 581.796326,935.214233 
	C579.782776,935.740356 578.452087,938.879700 576.564941,941.184692 
	C576.295898,949.688416 576.275513,957.848633 576.092163,966.280701 
	C575.259644,967.019470 574.590027,967.486328 573.920410,967.953247 
z"/>
<path fill="#C96FE2" opacity="1.000000" stroke="none" 
	d="
M866.937500,910.777710 
	C869.328125,910.858582 871.718750,910.939392 874.879028,911.183838 
	C876.102234,911.541016 876.538452,911.818420 877.011719,911.915833 
	C894.736145,915.564026 902.577698,930.825928 897.905823,947.634460 
	C894.707825,959.140198 885.843689,964.328796 874.998535,966.982056 
	C866.640747,967.008423 858.282959,967.034790 849.239319,967.040894 
	C848.553467,967.020569 848.100708,966.899658 848.069092,966.440796 
	C848.047668,947.678772 848.057922,929.375549 848.066650,911.032104 
	C848.065063,910.991821 847.986389,910.973694 847.986389,910.973755 
	C852.700989,910.948486 857.415588,910.923157 862.979736,910.909790 
	C864.865417,910.873718 865.901428,910.825745 866.937500,910.777710 
M873.522034,955.355103 
	C881.940735,953.289429 886.266296,947.626038 886.201111,938.754700 
	C886.139343,930.335938 881.519592,923.946167 873.566956,922.552917 
	C869.293762,921.804321 864.781555,922.419800 860.433289,922.419800 
	C860.433289,933.756226 860.433289,944.650513 860.433289,955.617126 
	C864.785034,955.617126 868.747498,955.617126 873.522034,955.355103 
z"/>
<path fill="#CA72E2" opacity="1.000000" stroke="none" 
	d="
M663.090637,947.998474 
	C663.025146,951.030945 662.959656,954.063416 662.893677,957.945923 
	C662.928162,959.863953 662.963196,960.931946 662.998230,962.000000 
	C662.964111,962.751282 662.929993,963.502502 662.876404,964.929932 
	C662.223389,966.373962 661.589905,967.141785 660.956421,967.909546 
	C657.951843,967.934021 654.947266,967.958496 651.345825,967.564880 
	C650.557190,959.377441 650.304565,951.609009 650.192627,943.838562 
	C650.100098,937.413513 650.173706,930.986145 650.173706,923.957458 
	C655.475647,924.848206 660.783569,921.419189 663.822632,926.416321 
	C669.499146,924.818115 674.608704,923.379456 678.960754,922.154114 
	C680.023499,918.895691 681.009277,915.873413 681.994995,912.851074 
	C685.412598,912.851074 688.830200,912.851074 692.732849,912.851074 
	C692.840759,915.305847 692.924438,917.209900 693.010742,919.961670 
	C694.159973,921.873047 695.152710,923.507019 696.488892,923.867310 
	C698.511353,924.412720 700.772888,924.071289 702.955139,924.557495 
	C702.977417,927.940369 702.977417,930.872620 702.977417,933.943298 
	C699.344238,934.159302 696.416931,934.333313 693.022949,934.535034 
	C693.022949,940.983826 692.763550,947.122559 693.224426,953.206726 
	C693.326355,954.552551 695.804016,956.177185 697.506653,956.796143 
	C698.842407,957.281738 700.670288,956.413635 702.531921,956.105042 
	C702.833679,958.557129 703.112122,960.819092 703.370056,963.419800 
	C703.464111,964.412048 703.578674,965.065613 703.693176,965.719116 
	C703.693176,965.719116 703.700806,965.786621 703.360291,965.828369 
	C700.807556,966.463501 698.588867,967.033691 696.384460,967.654358 
	C688.823608,969.783386 683.861816,967.330811 680.947266,960.002441 
	C680.950806,959.566345 680.954346,959.130188 680.973206,957.984497 
	C680.915161,956.508484 680.841858,955.742126 680.796631,954.510254 
	C680.800537,948.050842 680.776550,942.057007 680.759155,935.811768 
	C680.521362,935.351868 680.276855,935.143311 679.822876,934.873657 
	C679.613342,934.812561 679.177368,934.792603 678.974915,934.609009 
	C678.159546,934.598328 677.546570,934.771301 676.633850,935.147217 
	C674.687561,935.977966 673.074402,936.719482 671.388550,937.212708 
	C665.912170,938.815002 663.263000,942.200928 663.090637,947.998474 
z"/>
<path fill="#C96FE2" opacity="1.000000" stroke="none" 
	d="
M807.914307,967.948975 
	C805.253235,967.961060 802.592102,967.973083 799.394897,967.724609 
	C798.666199,967.189819 798.410034,967.002625 798.058533,966.443481 
	C797.996704,946.995667 797.966675,928.006775 798.256226,908.764648 
	C800.865051,908.414368 803.162231,908.405518 805.441467,908.199463 
	C808.789001,907.896973 810.242249,909.134155 810.039124,912.669495 
	C809.823059,916.431152 810.083923,920.220276 810.072998,924.369873 
	C810.067627,925.955322 810.125732,927.168213 810.214478,929.021851 
	C812.163147,927.423950 813.620605,926.228821 815.422119,924.873474 
	C823.962952,921.457031 831.058105,923.366577 837.012329,929.572327 
	C843.901062,936.752319 845.061279,950.571533 839.340881,958.764587 
	C834.242065,966.067505 824.104187,971.656433 816.411133,966.836670 
	C812.778442,964.560791 810.440186,965.230957 807.914307,967.948975 
M828.572876,952.756287 
	C829.132507,950.202942 830.108154,947.659424 830.169128,945.094238 
	C830.316772,938.887573 826.889771,934.605896 821.769043,933.948792 
	C816.247009,933.240295 812.479797,935.574585 810.570740,940.887634 
	C808.667542,946.184265 810.540283,953.058289 814.618835,955.746277 
	C818.633789,958.392273 823.730042,957.496521 828.572876,952.756287 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M490.208618,931.891785 
	C490.192474,928.934937 490.176331,925.978149 490.479279,922.369751 
	C496.916107,905.278992 522.101013,908.362793 530.683716,917.643433 
	C530.105225,918.608643 529.553223,919.628845 528.909607,920.587463 
	C524.678772,926.888000 524.849915,926.462952 517.694153,923.443115 
	C514.253784,921.991211 509.928802,921.845337 506.131653,922.263550 
	C502.711243,922.640198 501.934906,926.757202 504.614044,929.153320 
	C506.028992,930.418884 507.970123,931.213501 509.798431,931.876831 
	C514.763977,933.678345 520.075439,934.743652 524.743591,937.102356 
	C534.479065,942.021606 536.099487,954.589539 528.227112,962.194031 
	C520.975159,969.199158 512.227539,969.934265 502.996399,967.336060 
	C497.326233,965.740051 492.198090,963.078308 488.351410,958.480347 
	C494.179749,950.278442 494.178711,950.280579 502.832947,954.529297 
	C507.093292,956.620850 511.441376,958.053772 516.195923,956.206055 
	C520.591919,954.497620 521.237061,950.830200 517.458801,948.018616 
	C516.084106,946.995667 514.254028,946.455872 512.548340,946.027954 
	C503.533081,943.766357 494.491699,941.609985 490.208618,931.891785 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M941.148315,941.002502 
	C941.095215,947.916931 940.775085,954.843201 941.080933,961.741699 
	C941.291565,966.491821 939.588196,968.457825 934.902344,968.028625 
	C933.267944,967.878906 931.610962,967.976135 929.356628,967.601807 
	C927.386597,966.762878 925.899048,965.672058 924.686340,965.920898 
	C921.509705,966.572632 918.468079,968.601624 915.345581,968.661133 
	C906.659119,968.826782 901.278564,962.676941 901.146851,953.316711 
	C901.050232,946.456787 906.367493,941.494141 914.574219,941.080261 
	C918.942749,940.860046 923.331482,941.041260 927.912292,941.041260 
	C928.108398,937.262573 926.139282,935.033386 922.140930,935.063843 
	C917.250366,935.101074 912.362793,935.532288 907.593750,935.786682 
	C907.023865,934.450745 906.573120,933.400391 906.127319,932.347900 
	C904.077515,927.508850 904.520264,926.041077 909.649597,925.256470 
	C915.336365,924.386719 921.191589,923.837769 926.910767,924.184082 
	C932.436646,924.518860 936.843018,927.589600 939.207947,933.330933 
	C939.830627,935.119263 940.396973,936.567383 940.943176,938.390503 
	C940.998108,939.511108 941.073242,940.256775 941.148315,941.002502 
M919.632446,959.130371 
	C920.791626,959.075073 922.003662,959.219177 923.100281,958.928162 
	C926.830933,957.938049 929.500244,955.739624 929.032043,951.569702 
	C928.587097,947.607178 924.985535,948.513123 922.423645,948.115784 
	C921.154175,947.918884 919.421143,947.795532 918.558533,948.476196 
	C916.533875,950.073792 913.816956,951.953918 913.375488,954.125366 
	C912.718750,957.355713 915.558533,958.939148 919.632446,959.130371 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M642.953857,967.908569 
	C640.279297,967.936035 637.604675,967.963440 634.356506,967.648132 
	C631.911865,964.100037 629.484558,965.867004 627.359985,966.753052 
	C619.028320,970.227722 611.166138,968.079590 606.281616,960.408386 
	C606.261658,956.019897 606.299744,952.080444 606.595337,947.923035 
	C609.783447,942.322144 615.002869,941.413025 620.343384,941.050049 
	C624.167664,940.790100 628.023804,940.999451 632.317932,940.999451 
	C631.200256,936.706787 629.402771,934.761414 625.838806,935.007751 
	C621.907715,935.279419 617.981201,935.616699 614.052856,935.928223 
	C609.189819,936.313904 610.512207,931.945435 609.634705,929.608887 
	C609.297974,928.712158 611.231262,925.910645 612.467957,925.662842 
	C617.130615,924.728516 621.926208,924.137268 626.682922,924.056213 
	C638.277588,923.858704 643.467651,928.506714 645.140503,940.526306 
	C645.028198,949.649841 644.977295,958.314575 644.633301,967.001343 
	C643.878052,967.318420 643.415955,967.613525 642.953857,967.908569 
M621.205750,958.921570 
	C623.808228,958.755554 626.925232,959.416565 628.875916,958.202942 
	C631.051331,956.849426 632.166626,953.791931 633.743347,951.476074 
	C631.778687,950.351990 629.908325,948.973328 627.814636,948.197327 
	C626.518738,947.717102 624.600220,947.647705 623.461182,948.286011 
	C621.312317,949.490173 618.780090,951.004150 617.864075,953.043701 
	C617.309326,954.278748 619.490601,956.742676 621.205750,958.921570 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M1011.056946,936.996033 
	C1011.062317,937.761597 1011.067627,938.527161 1011.077148,940.130493 
	C1011.035217,949.635010 1010.989075,958.301636 1010.942993,966.968384 
	C1007.603394,967.306519 1004.263794,967.644714 1000.346191,967.633667 
	C998.237366,964.451721 996.250671,965.389160 993.951233,966.315369 
	C985.529846,969.707764 978.782959,968.499084 973.967590,962.592529 
	C973.277527,958.480957 972.604980,954.751404 971.993408,950.655579 
	C974.088440,944.246277 978.666443,941.191650 986.157166,941.016846 
	C990.039062,940.926270 993.924866,941.000977 998.215637,941.000977 
	C997.296814,936.793457 995.582642,934.760193 991.969666,935.016602 
	C988.691040,935.249390 985.424072,935.643921 982.150330,935.949341 
	C979.227905,936.222107 976.643921,935.611267 976.185425,932.250366 
	C975.898499,930.147156 973.646912,927.749878 977.285339,925.894043 
	C990.036499,919.390320 1005.642029,924.412537 1011.056946,936.996033 
M983.855469,955.816406 
	C987.172791,959.906433 994.472107,960.545349 997.542847,956.561035 
	C998.817444,954.907227 998.654236,952.145325 999.144897,949.887329 
	C995.650085,949.378540 992.100769,948.243286 988.676086,948.541077 
	C985.399780,948.825928 983.020386,951.176208 983.855469,955.816406 
z"/>
<path fill="#CA6EE4" opacity="1.000000" stroke="none" 
	d="
M765.098572,924.994019 
	C765.074036,933.020020 765.049500,941.045898 765.075012,949.799866 
	C765.164978,950.700989 765.204773,950.874084 765.258545,951.290283 
	C765.395447,951.854004 765.609558,952.092834 765.945923,952.598694 
	C770.518799,958.762268 773.907043,958.309875 779.372009,951.133911 
	C779.575134,951.081726 779.987000,950.995911 780.287842,950.705566 
	C780.726685,943.631165 781.061096,936.843262 780.928101,930.064575 
	C780.849792,926.069092 782.422302,924.895081 786.228333,924.909851 
	C790.014160,924.924561 791.098450,926.478149 791.048279,930.022400 
	C790.902710,940.313904 791.079590,950.610107 790.914734,960.901001 
	C790.887817,962.582031 789.556519,965.732300 789.187195,965.679749 
	C786.443420,965.289246 782.957092,968.141357 780.917908,963.845886 
	C780.066467,962.052368 778.161987,960.779297 777.030518,964.055786 
	C777.005676,964.047668 777.052856,964.044312 776.837158,964.114624 
	C776.345276,964.336548 776.146912,964.557922 775.701050,965.013306 
	C765.515320,972.601990 752.839966,966.258911 752.975586,955.616211 
	C752.927063,954.485352 752.839783,953.733032 752.775330,952.514282 
	C752.798096,942.847961 752.798096,933.648010 752.798096,923.580383 
	C757.461548,924.116333 761.280029,924.555176 765.098572,924.994019 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M779.168884,951.186035 
	C773.907043,958.309875 770.518799,958.762268 765.914856,952.356934 
	C765.744202,951.446838 765.541565,951.207092 765.244629,951.047119 
	C765.204773,950.874084 765.164978,950.700989 765.151611,950.262817 
	C766.678894,950.517883 768.213074,950.958740 769.674316,951.573120 
	C774.000305,953.392212 776.176453,951.617798 776.199707,946.886292 
	C776.235535,939.599854 776.471741,932.289612 777.213501,925.049988 
	C777.386292,923.363342 779.668884,921.216797 781.455994,920.543823 
	C783.655273,919.715515 786.398560,919.870056 788.825439,920.199341 
	C794.637268,920.987793 795.909607,919.810608 794.844788,913.994324 
	C793.617920,907.293457 795.403259,905.035400 801.959045,905.000916 
	C803.125305,904.994751 804.291504,904.998108 805.457764,905.000305 
	C811.938049,905.012512 813.669678,906.659424 813.997009,913.119507 
	C814.423096,921.528870 815.024231,922.434998 823.042297,920.821899 
	C829.636353,919.495300 834.126099,922.512268 838.630859,926.188110 
	C840.157715,927.433960 841.388855,929.042236 843.130249,930.880920 
	C846.100952,927.115784 844.778503,923.071960 844.612610,919.106201 
	C844.492615,916.238037 845.017883,913.335144 845.427917,910.472412 
	C845.451355,910.308655 847.035217,910.368347 847.939636,910.648804 
	C847.986389,910.973694 848.065063,910.991821 847.743042,911.293335 
	C847.303772,929.500916 847.177673,947.406860 847.113037,965.313110 
	C847.111145,965.840698 847.756958,966.370728 848.100708,966.899658 
	C848.100708,966.899658 848.553467,967.020569 848.773865,967.100220 
	C847.371399,970.823792 845.869507,968.615051 845.044250,966.840576 
	C844.127075,964.868408 843.879089,962.585022 843.019043,959.159668 
	C838.793274,966.170959 833.265381,969.958435 826.173157,971.063049 
	C824.013855,971.399414 821.693359,971.337158 819.541321,970.942078 
	C815.873230,970.268677 812.280029,969.187744 808.284790,968.113525 
	C810.440186,965.230957 812.778442,964.560791 816.411133,966.836670 
	C824.104187,971.656433 834.242065,966.067505 839.340881,958.764587 
	C845.061279,950.571533 843.901062,936.752319 837.012329,929.572327 
	C831.058105,923.366577 823.962952,921.457031 815.053284,924.857422 
	C812.939148,924.666748 811.537842,924.332031 810.136475,923.997314 
	C810.083923,920.220276 809.823059,916.431152 810.039124,912.669495 
	C810.242249,909.134155 808.789001,907.896973 805.441467,908.199463 
	C803.162231,908.405518 800.865051,908.414368 797.966797,909.059448 
	C797.259094,928.176636 797.151978,946.745789 797.105469,965.315125 
	C797.104126,965.843445 797.747498,966.373291 798.090271,966.902466 
	C798.410034,967.002625 798.666199,967.189819 798.952698,967.686279 
	C796.222473,968.020203 793.398254,968.131958 790.480896,967.778625 
	C790.934692,966.079407 791.873779,964.869934 791.953247,963.606323 
	C792.171326,960.142334 792.028137,956.655518 792.028076,953.177734 
	C792.028015,943.625122 792.028015,934.072510 792.028015,924.130066 
	C787.646790,924.130066 783.881287,924.130066 779.783813,924.374390 
	C779.357483,933.474487 779.263184,942.330261 779.168884,951.186035 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M949.995483,912.858887 
	C952.702759,912.899048 955.410095,912.939148 958.874023,913.177368 
	C959.754517,914.019531 959.958618,914.659607 959.989990,915.308105 
	C960.374451,923.255127 960.407227,923.274109 968.209106,924.069763 
	C968.954163,924.145813 969.651184,924.693604 970.257996,925.463501 
	C970.028931,928.476868 969.912292,931.049072 969.775452,934.066650 
	C967.168945,934.066650 965.025940,933.857178 962.955994,934.155334 
	C961.928955,934.303284 960.208435,935.388062 960.211731,936.041931 
	C960.242188,942.078308 960.581116,948.113098 960.914612,954.516479 
	C963.460205,959.473694 967.022522,955.460205 970.001587,956.483215 
	C970.039001,959.620178 970.072266,962.310120 969.862671,965.278076 
	C967.929688,966.215454 966.292664,967.160278 964.540466,967.486328 
	C955.128784,969.237854 947.598633,966.747925 948.009521,954.913513 
	C948.216492,948.951660 947.949219,942.973328 947.946533,936.619141 
	C945.951904,935.156494 943.907166,934.077209 941.916992,932.561096 
	C942.623108,929.465332 942.496460,925.295898 944.093994,924.472656 
	C947.096985,922.925293 947.039551,921.033325 947.069031,918.591736 
	C947.097839,916.210327 946.434265,913.447998 949.995483,912.858887 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M798.050659,541.511536 
	C817.174194,544.620300 835.251831,551.405212 852.062378,560.990723 
	C864.452209,568.055603 876.247925,576.286865 887.713806,584.799255 
	C894.184509,589.603149 899.458862,596.020569 905.258911,601.724609 
	C908.054443,604.473877 911.043884,606.547119 915.316711,605.843689 
	C919.250366,605.195984 922.349976,603.370728 923.514465,599.538635 
	C924.718872,595.575134 924.232910,591.842957 921.073975,588.506165 
	C902.023376,568.383179 879.841125,552.566345 855.168274,540.128235 
	C835.362732,530.143921 814.433350,523.528564 792.350769,519.161011 
	C794.078918,518.316833 796.342102,516.792725 798.180298,517.197754 
	C806.005493,518.922058 813.905884,520.670288 821.405396,523.426025 
	C834.292419,528.161499 847.275269,532.903137 859.537048,539.009521 
	C877.386169,547.898438 893.897827,559.029419 908.443604,572.879028 
	C913.118347,577.330078 918.129089,581.431702 923.040894,585.629089 
	C927.451233,589.398071 928.743164,598.415283 925.192627,602.266907 
	C917.343994,610.780945 913.107971,610.879456 905.624207,604.938171 
	C894.148438,595.827759 883.667419,585.425720 871.928589,576.695374 
	C857.006165,565.597290 840.642639,556.755554 822.816467,551.022583 
	C815.685791,548.729309 808.370178,547.014404 801.210327,544.804199 
	C799.988770,544.427124 799.129089,542.877808 798.050659,541.511536 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M752.936890,955.994812 
	C752.839966,966.258911 765.515320,972.601990 775.943848,964.999878 
	C776.817749,964.650024 776.997986,964.390747 777.052856,964.044312 
	C777.052856,964.044312 777.005676,964.047668 777.373047,964.064209 
	C778.835815,965.354248 779.931213,966.627747 781.026611,967.901245 
	C773.751038,969.220764 766.631287,974.440430 759.075073,969.101013 
	C755.916931,966.869385 753.072083,964.194275 749.717285,961.416443 
	C749.302795,961.712280 748.332825,962.270386 747.521484,963.003906 
	C738.990417,970.717712 729.742798,974.357483 718.272339,969.411133 
	C713.999512,967.568604 709.161011,967.037842 704.136841,965.814026 
	C703.578674,965.065613 703.464111,964.412048 703.772217,963.392090 
	C704.822998,962.617371 705.451172,962.209045 706.434021,961.883179 
	C713.052307,964.194580 719.175781,967.527893 725.618530,968.345825 
	C732.214783,969.183228 739.321960,967.245667 744.057373,962.000061 
	C751.609741,953.634216 750.439148,939.572937 736.387085,935.263000 
	C731.655701,933.811829 726.802063,932.597290 722.324097,930.568726 
	C720.735596,929.849060 719.308472,927.026001 719.326294,925.177856 
	C719.336548,924.106506 722.464355,922.054260 724.022034,922.202271 
	C728.338806,922.612549 732.709717,923.557922 736.781494,925.049866 
	C739.483948,926.040039 741.133118,926.026733 742.594421,923.538330 
	C743.819275,921.452576 745.114624,919.408203 746.336670,917.414185 
	C731.457825,905.270813 713.293457,907.950684 705.503662,922.999695 
	C704.423950,923.372009 703.678406,923.739380 702.932861,924.106750 
	C700.772888,924.071289 698.511353,924.412720 696.488892,923.867310 
	C695.152710,923.507019 694.159973,921.873047 693.068176,920.404175 
	C693.788208,919.999329 694.472473,919.896790 695.115723,920.015991 
	C700.750305,921.060486 704.567200,920.204834 707.910706,914.192383 
	C711.950439,906.928162 726.884583,904.253479 734.528381,907.775208 
	C738.409424,909.563232 742.393921,911.157776 746.136536,913.197693 
	C747.392822,913.882507 748.628235,915.573730 748.870850,916.978149 
	C749.792358,922.311340 745.820007,925.381042 742.253967,927.781738 
	C740.609802,928.888428 737.162781,927.675964 734.653320,927.040833 
	C731.965210,926.360413 729.480103,924.821045 726.781128,924.267090 
	C725.609741,924.026672 724.125000,925.313293 721.771606,926.353088 
	C731.271912,930.134949 739.281250,933.323303 748.221375,936.882202 
	C748.394897,935.531982 748.933167,933.180481 748.958740,930.823486 
	C749.062744,921.223755 755.811462,916.958008 764.633240,921.036438 
	C766.435669,921.869751 768.062561,922.790955 765.441467,924.930481 
	C761.280029,924.555176 757.461548,924.116333 752.798096,923.580383 
	C752.798096,933.648010 752.798096,942.847961 752.579773,952.730225 
	C752.553223,954.273254 752.745056,955.134033 752.936890,955.994812 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M489.900452,932.123047 
	C494.491699,941.609985 503.533081,943.766357 512.548340,946.027954 
	C514.254028,946.455872 516.084106,946.995667 517.458801,948.018616 
	C521.237061,950.830200 520.591919,954.497620 516.195923,956.206055 
	C511.441376,958.053772 507.093292,956.620850 502.832947,954.529297 
	C494.178711,950.280579 494.179749,950.278442 488.351410,958.480347 
	C492.198090,963.078308 497.326233,965.740051 502.996399,967.336060 
	C512.227539,969.934265 520.975159,969.199158 528.227112,962.194031 
	C536.099487,954.589539 534.479065,942.021606 524.743591,937.102356 
	C520.075439,934.743652 514.763977,933.678345 509.798431,931.876831 
	C507.970123,931.213501 506.028992,930.418884 504.614044,929.153320 
	C501.934906,926.757202 502.711243,922.640198 506.131653,922.263550 
	C509.928802,921.845337 514.253784,921.991211 517.694153,923.443115 
	C524.849915,926.462952 524.678772,926.888000 528.909607,920.587463 
	C529.553223,919.628845 530.105225,918.608643 530.683716,917.643433 
	C522.101013,908.362793 496.916107,905.278992 490.538910,921.931030 
	C490.210327,920.569397 489.524506,918.658264 490.170868,917.474548 
	C494.821259,908.957703 507.654694,904.040466 516.833862,907.105347 
	C520.883667,908.457458 524.968994,909.800354 528.828735,911.597351 
	C532.721130,913.409607 534.242310,918.184021 532.533020,921.703369 
	C529.534729,927.876709 525.304810,930.007385 520.473999,927.742188 
	C516.535889,925.895569 512.647522,923.733032 506.975220,926.417786 
	C510.872040,928.156616 513.659363,930.063782 516.693298,930.615601 
	C522.979248,931.758911 528.231873,934.381531 532.613770,938.983032 
	C533.208923,939.607971 534.395447,939.669800 535.679321,939.995544 
	C536.393616,942.238098 536.965332,944.470093 537.036499,946.718079 
	C537.238770,953.096375 537.208618,959.481995 537.370117,965.862122 
	C537.382446,966.347595 538.182617,966.813049 538.828125,967.601562 
	C537.726440,967.490662 535.925232,967.441406 535.231995,966.540039 
	C534.480164,965.562439 534.760193,963.791260 534.517761,961.758423 
	C533.073486,962.455811 531.779602,962.664001 531.224976,963.416809 
	C527.982056,967.819214 523.531311,969.702820 518.324646,970.882202 
	C513.430298,971.990845 509.026825,971.990356 504.383423,969.828247 
	C499.993378,967.784180 495.084137,966.839478 490.734985,964.727905 
	C486.650787,962.744995 485.318665,957.918701 487.185699,954.655396 
	C490.711121,948.493530 495.527618,946.387634 500.122467,949.054199 
	C504.581848,951.642090 509.079498,953.977905 514.904480,952.451721 
	C514.198181,948.559326 511.314209,947.917297 508.334259,946.928894 
	C503.216949,945.231506 498.059357,943.445740 493.293396,940.974426 
	C490.228912,939.385498 487.915985,936.470032 489.900452,932.123047 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M949.932190,912.526001 
	C946.434265,913.447998 947.097839,916.210327 947.069031,918.591736 
	C947.039551,921.033325 947.096985,922.925293 944.093994,924.472656 
	C942.496460,925.295898 942.623108,929.465332 941.578186,932.561890 
	C940.507080,932.996582 939.829346,932.993713 939.151550,932.990845 
	C936.843018,927.589600 932.436646,924.518860 926.910767,924.184082 
	C921.191589,923.837769 915.336365,924.386719 909.649597,925.256470 
	C904.520264,926.041077 904.077515,927.508850 906.127319,932.347900 
	C906.573120,933.400391 907.023865,934.450745 907.593750,935.786682 
	C912.362793,935.532288 917.250366,935.101074 922.140930,935.063843 
	C926.139282,935.033386 928.108398,937.262573 927.912292,941.041260 
	C923.331482,941.041260 918.942749,940.860046 914.574219,941.080261 
	C906.367493,941.494141 901.050232,946.456787 901.146851,953.316711 
	C901.278564,962.676941 906.659119,968.826782 915.345581,968.661133 
	C918.468079,968.601624 921.509705,966.572632 924.686340,965.920898 
	C925.899048,965.672058 927.386597,966.762878 928.907715,967.558289 
	C922.087463,970.327087 915.122009,974.766296 907.820435,969.023254 
	C904.038513,966.048462 900.648315,962.575623 896.372559,958.682739 
	C895.834351,959.143738 894.321472,960.826050 892.468933,961.947021 
	C888.423218,964.395386 884.303101,966.779114 879.994507,968.697876 
	C878.766235,969.244934 876.785706,968.102905 875.074341,967.358765 
	C885.843689,964.328796 894.707825,959.140198 897.905823,947.634460 
	C902.577698,930.825928 894.736145,915.564026 877.011719,911.915833 
	C876.538452,911.818420 876.102234,911.541016 875.326050,911.150269 
	C877.275940,910.452698 879.894653,908.968323 881.760498,909.624512 
	C889.323120,912.284180 895.409912,917.041931 899.182556,924.368042 
	C899.709595,925.391479 900.369812,926.352112 901.037415,927.292847 
	C901.184204,927.499695 901.624756,927.498047 901.932495,927.588989 
	C906.331177,920.646179 913.370544,921.342896 920.234253,920.905396 
	C927.429504,920.446716 933.956787,921.840088 940.254883,926.796631 
	C941.748901,922.280151 942.945801,918.654175 944.148132,915.029968 
	C945.004028,912.450134 947.261841,911.333496 949.932190,912.526001 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M714.865356,556.760681 
	C701.288513,561.364075 687.173584,564.729126 673.872070,570.106323 
	C654.180664,578.066467 635.747314,588.567017 620.186279,603.397095 
	C615.300110,608.053772 610.393372,612.750671 606.054260,617.899475 
	C602.816589,621.741272 603.411682,626.219543 606.662354,630.086670 
	C610.973633,635.215576 617.295532,635.164368 622.550049,629.925171 
	C636.892639,615.624634 652.823303,603.482422 670.823730,594.117981 
	C687.336670,585.527283 704.949341,580.334167 723.603882,577.148376 
	C722.931274,578.421875 722.080627,580.286133 720.738281,580.761230 
	C717.643555,581.856689 714.341064,582.585876 711.067688,582.915833 
	C699.885315,584.043030 689.815796,588.576294 679.832153,593.152710 
	C660.189026,602.156860 642.622070,614.150757 627.454712,629.736877 
	C624.177429,633.104675 619.155212,635.630920 614.527039,636.611816 
	C608.681519,637.850647 600.835693,628.442871 602.257019,622.375732 
	C603.082886,618.850586 604.827576,615.116821 607.238464,612.454712 
	C624.782227,593.082764 646.037231,578.621460 669.860352,568.326538 
	C681.432800,563.325745 694.009277,560.635925 706.159058,556.992676 
	C708.916016,556.165955 711.616516,553.001953 714.865356,556.760681 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M788.006470,579.552490 
	C817.906982,584.687927 843.673401,598.612793 866.580566,618.189392 
	C870.980164,621.949280 874.811401,626.370239 878.952820,630.437378 
	C883.636230,635.036621 889.318481,635.093872 893.834045,630.658020 
	C898.289185,626.281555 898.384766,620.258118 894.010010,615.565552 
	C880.738770,601.329956 865.575378,589.434204 848.662720,579.881165 
	C829.555542,569.088501 809.166382,561.604553 787.268188,557.324097 
	C788.575012,556.424683 790.467590,554.848511 791.717712,555.253845 
	C807.686096,560.431458 824.193970,564.555664 839.275208,571.678101 
	C852.916687,578.120605 865.262695,587.515259 877.615967,596.392944 
	C884.301208,601.197388 889.803162,607.646973 895.846741,613.346191 
	C902.031860,619.178833 901.289490,633.284851 890.207031,636.121521 
	C885.022827,637.448425 880.885559,635.184387 877.689514,632.008545 
	C862.122375,616.540100 844.646545,603.613281 824.911682,594.264343 
	C814.280334,589.228088 802.459473,586.718811 791.232178,582.901978 
	C789.970642,582.473145 789.080322,580.952515 788.006470,579.552490 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M645.201904,940.067566 
	C643.467651,928.506714 638.277588,923.858704 626.682922,924.056213 
	C621.926208,924.137268 617.130615,924.728516 612.467957,925.662842 
	C611.231262,925.910645 609.297974,928.712158 609.634705,929.608887 
	C610.512207,931.945435 609.189819,936.313904 614.052856,935.928223 
	C617.981201,935.616699 621.907715,935.279419 625.838806,935.007751 
	C629.402771,934.761414 631.200256,936.706787 632.317932,940.999451 
	C628.023804,940.999451 624.167664,940.790100 620.343384,941.050049 
	C615.002869,941.413025 609.783447,942.322144 606.196411,947.880188 
	C604.748291,948.033691 603.956482,948.012085 603.164673,947.990540 
	C603.130127,946.601440 603.095520,945.212402 603.125488,943.410400 
	C609.753113,942.446533 610.362000,941.559143 608.381104,935.615051 
	C607.963684,934.362488 607.717407,933.051575 607.415161,931.762207 
	C606.079468,926.063171 607.868286,922.428345 613.715149,921.789001 
	C621.751160,920.910217 630.076355,918.025696 637.902100,922.861877 
	C640.258179,924.317871 642.500000,925.958801 645.441162,927.953857 
	C646.941345,921.305359 651.730713,919.418335 657.946350,920.001648 
	C658.111633,920.017151 658.314453,919.947021 658.440552,920.019653 
	C664.397034,923.450867 670.229980,920.986328 676.456238,919.357971 
	C678.531494,917.578857 674.768494,910.669128 681.936279,912.532104 
	C681.009277,915.873413 680.023499,918.895691 678.960754,922.154114 
	C674.608704,923.379456 669.499146,924.818115 663.822632,926.416321 
	C660.783569,921.419189 655.475647,924.848206 650.173706,923.957458 
	C650.173706,930.986145 650.100098,937.413513 650.192627,943.838562 
	C650.304565,951.609009 650.557190,959.377441 650.896973,967.528931 
	C648.487427,968.095459 645.929810,968.279846 643.163025,968.186401 
	C643.415955,967.613525 643.878052,967.318420 644.951538,966.726929 
	C645.442566,957.642883 645.322266,948.855225 645.201904,940.067566 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M970.370483,925.022400 
	C969.651184,924.693604 968.954163,924.145813 968.209106,924.069763 
	C960.407227,923.274109 960.374451,923.255127 959.989990,915.308105 
	C959.958618,914.659607 959.754517,914.019531 959.319458,913.108398 
	C959.815002,912.324951 961.188171,911.237244 961.337036,911.384766 
	C962.325500,912.364563 963.494446,913.522888 963.740723,914.791016 
	C963.994202,916.096375 963.265015,917.592590 962.981262,918.932251 
	C970.358704,922.054077 976.926086,922.617371 984.942383,920.765442 
	C993.902771,918.695435 1004.106873,919.777954 1011.073853,928.478333 
	C1013.339966,931.308289 1013.825439,933.848572 1011.427429,936.914673 
	C1005.642029,924.412537 990.036499,919.390320 977.285339,925.894043 
	C973.646912,927.749878 975.898499,930.147156 976.185425,932.250366 
	C976.643921,935.611267 979.227905,936.222107 982.150330,935.949341 
	C985.424072,935.643921 988.691040,935.249390 991.969666,935.016602 
	C995.582642,934.760193 997.296814,936.793457 998.215637,941.000977 
	C993.924866,941.000977 990.039062,940.926270 986.157166,941.016846 
	C978.666443,941.191650 974.088440,944.246277 971.747986,950.885742 
	C970.960144,953.000244 970.478760,954.518188 969.997437,956.036194 
	C967.022522,955.460205 963.460205,959.473694 960.979980,954.038208 
	C960.979187,949.400330 961.269165,945.585571 960.947937,941.822876 
	C960.528748,936.914185 961.773254,934.115234 967.358398,935.006042 
	C970.137085,935.449158 971.328125,934.051575 971.030579,931.302124 
	C970.804138,929.209595 970.590088,927.115662 970.370483,925.022400 
M968.083130,951.697205 
	C970.204956,948.463501 972.455261,945.303772 974.380554,941.957031 
	C975.076538,940.747314 975.406982,938.986084 975.080872,937.682434 
	C974.931641,937.085571 972.806519,936.830566 971.549500,936.708862 
	C970.428894,936.600342 969.094788,936.566650 968.164795,937.071228 
	C966.351990,938.054871 963.411438,939.348389 963.323242,940.672546 
	C963.062378,944.587524 963.941223,948.583557 964.544800,952.521667 
	C964.559570,952.618042 966.569519,952.408508 968.083130,951.697205 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M590.802124,577.055969 
	C587.822449,580.523499 584.432007,583.940430 581.111389,587.423950 
	C575.935364,592.853943 575.696594,599.277649 580.447388,603.667786 
	C584.824585,607.712708 590.491516,607.070557 595.692566,601.786682 
	C620.637329,576.444763 649.668823,557.570740 683.542969,546.449524 
	C689.988281,544.333435 696.654602,542.890198 703.611816,541.153564 
	C702.938660,542.409851 702.062378,544.469910 700.778320,544.766907 
	C676.365540,550.412476 653.629456,559.834106 633.457092,574.830505 
	C622.618713,582.887878 612.175781,591.480225 601.627014,599.921814 
	C598.826233,602.163147 596.545654,605.120728 593.583191,607.079346 
	C588.159607,610.665161 585.596313,609.367371 578.970459,605.246948 
	C575.615234,603.160400 572.952820,595.318115 575.388428,592.176086 
	C579.713257,586.596924 585.340332,582.027283 590.802124,577.055969 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M865.339966,725.818726 
	C860.859863,711.805664 855.758240,698.038757 847.177307,685.808228 
	C826.749573,656.692261 798.780212,640.141235 763.283813,635.357056 
	C764.253662,631.224243 767.633850,632.788513 769.691040,633.256104 
	C776.287415,634.755005 782.695862,637.058350 789.230469,638.856445 
	C805.615295,643.364990 819.068420,652.523438 831.580017,663.820374 
	C842.071777,673.293518 850.296570,684.384216 857.341675,696.319336 
	C860.956482,702.443176 862.785217,709.699158 864.872009,716.609314 
	C865.716187,719.404785 869.240540,722.341125 865.339966,725.818726 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M773.076111,867.373230 
	C783.476929,863.221802 794.524658,860.546448 804.327148,855.467773 
	C837.991516,838.026245 857.871582,809.817383 865.876404,772.990540 
	C866.121338,771.863525 866.099731,770.678589 866.441772,769.220764 
	C867.442688,770.624756 869.165161,772.557678 868.821777,773.998291 
	C863.636292,795.755249 854.746277,815.582397 839.351624,832.338501 
	C829.465820,843.098755 818.106506,851.850037 805.374451,858.506897 
	C796.679688,863.052856 786.926025,865.604248 777.561157,868.799561 
	C776.351929,869.212158 774.634338,868.134766 773.076111,867.373230 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M851.104980,661.149536 
	C854.565186,661.891724 857.596008,664.119995 861.773315,661.640930 
	C868.901978,657.410217 870.508484,650.321411 864.877441,644.284363 
	C849.087769,627.356140 830.344543,614.689941 808.853271,606.194153 
	C802.235474,603.577942 795.294189,601.780334 788.227295,599.364624 
	C789.293884,598.583740 790.836060,597.312195 791.944397,597.617249 
	C812.594543,603.299316 831.554199,612.637817 848.598450,625.517639 
	C855.538391,630.761963 861.725220,637.105896 867.745911,643.433533 
	C873.193665,649.159058 872.063171,655.909424 865.439087,662.058350 
	C860.702942,666.454529 855.930725,666.277527 851.104980,661.149536 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M698.931396,520.654907 
	C688.456360,524.305115 677.738525,527.119507 667.402649,530.961304 
	C646.964172,538.558289 627.685547,548.437927 610.532593,562.126831 
	C605.009705,566.534302 599.378296,570.805786 593.452759,575.211792 
	C596.733032,571.397583 599.806030,566.707092 604.084045,563.774902 
	C617.148865,554.819946 630.214478,545.674011 644.152527,538.238037 
	C658.803101,530.421936 674.368591,524.220825 690.834656,520.919861 
	C693.447693,520.396057 696.182739,520.480469 698.931396,520.654907 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M947.896362,937.002441 
	C947.949219,942.973328 948.216492,948.951660 948.009521,954.913513 
	C947.598633,966.747925 955.128784,969.237854 964.540466,967.486328 
	C966.292664,967.160278 967.929688,966.215454 970.124756,965.073242 
	C971.748169,964.051697 972.866638,963.513123 973.985168,962.974609 
	C978.782959,968.499084 985.529846,969.707764 993.951233,966.315369 
	C996.250671,965.389160 998.237366,964.451721 999.906006,967.599670 
	C991.712158,971.507080 983.424683,973.910278 975.071472,967.807922 
	C974.334595,967.269592 972.444702,967.489746 971.579285,968.077576 
	C965.008362,972.541077 958.213623,972.599365 951.663452,968.627319 
	C945.925598,965.147827 944.287292,959.162964 944.019836,952.801025 
	C943.901245,949.980713 944.192078,947.131348 943.904480,944.335510 
	C943.785950,943.182312 942.635559,942.135254 941.551392,941.021240 
	C941.073242,940.256775 940.998108,939.511108 941.216064,938.203735 
	C943.231079,933.363892 945.719360,936.737488 947.896362,937.002441 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M732.893372,842.894775 
	C712.106995,839.176270 695.727478,828.416870 684.183105,810.618713 
	C676.960815,799.483948 673.501282,787.108765 673.185181,773.429871 
	C674.502014,775.263367 677.038208,777.699768 676.764038,779.762695 
	C675.770935,787.235657 679.168579,793.331543 681.888184,799.617554 
	C690.357971,819.194153 705.163635,831.858459 725.247253,838.580872 
	C727.995850,839.500977 731.969849,838.028015 732.893372,842.894775 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M723.957397,596.743042 
	C712.648376,600.597412 700.995544,603.379272 689.945984,607.702881 
	C672.295288,614.609497 656.817810,625.222656 643.100708,638.352295 
	C641.075623,640.290649 638.832886,642.001709 636.381714,643.952087 
	C636.889099,642.384216 637.299561,640.246887 638.585022,639.047119 
	C651.933777,626.588257 666.007446,615.037048 682.900330,607.642944 
	C691.756836,603.766357 700.804199,600.175476 710.055115,597.432922 
	C713.217773,596.495239 716.821472,596.721313 720.070374,595.156738 
	C720.945251,594.735413 722.591919,595.916626 723.957397,596.743042 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M646.114685,662.088623 
	C651.543274,657.395813 657.468384,653.319275 662.782593,648.560486 
	C681.041931,632.209778 702.489197,622.675537 726.320862,618.011902 
	C727.941406,617.694824 729.569336,617.415222 731.598511,617.168213 
	C730.670471,618.471313 729.451355,620.655884 727.987427,620.834534 
	C711.882324,622.800598 697.118713,628.658875 683.484619,636.992310 
	C674.955444,642.205505 667.371399,648.973816 659.417603,655.114746 
	C656.805603,657.131409 654.534607,659.636536 651.774536,661.395447 
	C650.333557,662.313721 648.168945,662.096497 646.114685,662.088623 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M769.052307,617.562683 
	C794.146362,620.302429 816.534668,629.960815 836.165222,645.878235 
	C840.748108,649.594299 844.650330,654.149841 849.031372,658.617188 
	C846.643005,657.126648 843.998047,655.453979 841.550293,653.530212 
	C827.751892,642.685669 813.668091,632.367920 796.651428,626.931396 
	C790.758972,625.048950 784.994568,622.768066 779.127136,620.802246 
	C778.155579,620.476746 776.845886,621.151794 775.880188,620.819214 
	C773.563293,620.021423 771.358032,618.899475 769.052307,617.562683 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M826.492798,789.047607 
	C819.592468,816.920532 801.713135,834.277100 774.152222,842.007996 
	C773.034485,842.321533 771.905396,842.594971 770.151489,842.951660 
	C769.350464,843.016968 769.179443,843.017822 769.008423,843.018677 
	C770.263123,841.692383 771.273987,839.830200 772.813416,839.129883 
	C780.678284,835.552612 789.413025,833.372437 796.473145,828.653381 
	C809.255371,820.109497 818.454773,808.211060 823.062073,793.158081 
	C823.528992,791.632568 825.090576,790.442078 826.492798,789.047607 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M548.162231,968.182373 
	C548.588013,967.141296 549.219727,966.373352 550.317078,965.212891 
	C551.160461,964.321045 551.858948,963.826904 551.867188,963.321533 
	C551.965149,957.336792 552.034241,951.348572 551.893433,945.366455 
	C551.874573,944.563965 550.658875,943.789734 549.997314,943.002441 
	C549.984314,942.554871 549.971252,942.107300 550.383789,941.251343 
	C551.170227,939.480347 551.531006,938.117859 551.891724,936.755310 
	C556.058350,933.142212 559.478943,935.093933 562.935669,938.742065 
	C563.231445,939.560242 563.414307,939.780579 563.676575,939.934937 
	C563.788391,947.235107 563.862915,954.536194 564.038757,961.834778 
	C564.081726,963.616943 564.457458,965.391174 564.860535,967.543091 
	C561.634033,968.827881 560.191284,967.723572 560.056335,963.445496 
	C559.868164,957.482056 560.003662,951.508423 559.999756,945.539124 
	C559.998840,944.212646 560.344666,942.753357 559.904785,941.592468 
	C559.323486,940.058350 558.125305,938.757935 557.192322,937.356995 
	C556.130554,938.795471 554.227783,940.191162 554.151978,941.679871 
	C553.814941,948.295349 554.097351,954.939575 553.960266,961.569275 
	C553.885803,965.166748 553.060791,968.462830 548.162231,968.182373 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M739.924194,634.681152 
	C730.362915,637.374512 720.498596,639.002625 711.133362,642.126770 
	C696.978210,646.848816 684.203430,654.399536 672.437500,664.168701 
	C673.708801,662.036011 674.898987,659.209167 677.027466,657.770996 
	C684.285278,652.866394 691.496948,647.603577 699.435974,644.073914 
	C708.318481,640.124939 717.949402,637.832947 727.307922,634.996094 
	C730.422180,634.052002 733.683899,633.521729 736.916443,633.087463 
	C737.822510,632.965698 738.864807,633.858398 739.924194,634.681152 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M657.073181,823.267944 
	C661.310486,827.330688 665.399048,831.896240 669.748230,836.198486 
	C685.224182,851.507324 703.587341,861.531433 724.938354,866.002930 
	C725.726990,866.168030 726.451782,866.638184 727.601868,867.031738 
	C726.697083,867.680359 725.172729,869.035217 724.129456,868.732361 
	C704.117188,862.923462 685.335815,854.744385 670.249756,839.750061 
	C666.640259,836.162476 662.920593,832.671448 659.525574,828.890015 
	C658.288086,827.511719 657.847900,825.417542 657.073181,823.267944 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M804.716980,682.057617 
	C805.372681,688.216309 805.666199,694.462158 805.849365,700.711304 
	C805.950806,704.172791 805.867615,707.639709 805.867615,711.905457 
	C812.485168,711.905457 818.726562,711.938049 824.967346,711.888428 
	C827.274963,711.870056 829.479492,711.784485 828.916260,715.570068 
	C822.450989,715.666077 816.052734,715.237976 809.647400,715.028137 
	C803.901306,714.839966 803.028870,714.107971 803.006775,708.381775 
	C802.978760,701.104370 802.929016,693.825500 803.059448,686.550354 
	C803.085938,685.075317 803.872559,683.613831 804.716980,682.057617 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M576.813599,940.841187 
	C578.452087,938.879700 579.782776,935.740356 581.796326,935.214233 
	C585.426880,934.265625 588.740173,936.076050 590.131226,940.917603 
	C590.099731,950.159363 590.092651,958.559631 590.085571,966.959900 
	C589.390381,965.374084 588.213318,963.826172 588.084961,962.195801 
	C587.579468,955.773621 587.651855,949.297729 586.940063,942.905273 
	C586.749573,941.194641 584.519653,938.362427 583.258362,938.379944 
	C579.860718,938.427002 578.961365,941.339539 578.985474,944.487305 
	C579.036438,951.137329 578.980530,957.788086 579.007141,964.438416 
	C579.019348,967.483276 578.154236,969.404236 574.254395,968.097534 
	C574.590027,967.486328 575.259644,967.019470 576.328125,965.948730 
	C576.755798,957.177002 576.784668,949.009094 576.813599,940.841187 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M679.177368,934.792603 
	C679.177368,934.792603 679.613342,934.812561 679.880737,935.097900 
	C680.279907,935.671814 680.481384,935.898438 680.752502,936.063110 
	C680.776550,942.057007 680.800537,948.050842 680.587585,954.748169 
	C680.258911,956.289978 680.167175,957.128357 680.075500,957.966675 
	C679.050903,956.585999 677.348206,955.299377 677.140869,953.805298 
	C676.689575,950.552429 677.146851,947.184509 676.934265,943.882751 
	C676.836853,942.368958 676.222168,939.761902 675.514526,939.645752 
	C673.988037,939.395081 671.916382,940.022583 670.668335,941.032715 
	C668.156006,943.066223 666.045044,945.595642 663.429565,947.959473 
	C663.263000,942.200928 665.912170,938.815002 671.388550,937.212708 
	C673.074402,936.719482 674.687561,935.977966 677.010254,935.171875 
	C678.183411,934.926636 678.680359,934.859558 679.177368,934.792603 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M603.083496,934.050537 
	C598.519104,923.580994 588.930603,919.879761 579.086548,925.650574 
	C575.296143,927.872498 572.734192,927.953674 569.557068,925.205872 
	C568.530029,924.317566 566.828369,924.209167 565.182251,923.502197 
	C565.693115,919.915833 568.207703,920.487061 569.891052,921.713013 
	C573.342834,924.226501 576.106140,924.101562 579.862793,921.933167 
	C587.526367,917.509338 600.850464,921.690002 604.437073,929.335693 
	C605.009338,930.555603 607.896912,933.375610 603.083496,934.050537 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M673.636597,715.991821 
	C672.533325,713.057312 673.454163,711.709961 676.721924,711.853943 
	C681.363586,712.058411 686.041504,711.634338 690.665771,711.983826 
	C694.942566,712.307251 696.538574,711.106995 696.339966,706.536865 
	C696.008972,698.922363 696.387085,691.277039 696.697327,683.305176 
	C697.285339,684.668396 697.850647,686.357361 697.962891,688.076050 
	C698.381042,694.477600 698.567688,700.894531 699.000244,707.294922 
	C699.383362,712.963928 697.582458,714.859558 691.766724,715.033020 
	C685.854065,715.209229 679.949402,715.651001 673.636597,715.991821 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M774.946045,516.485168 
	C768.618225,516.534485 762.236816,516.224854 755.117615,515.617676 
	C752.277344,515.478149 750.174927,515.636047 748.072510,515.793945 
	C741.335632,516.184448 734.598816,516.574951 727.431091,516.904724 
	C728.880493,515.565186 730.731384,513.232544 732.645691,513.179138 
	C744.800415,512.840454 756.972229,512.868408 769.130066,513.143860 
	C771.072388,513.187927 772.972778,515.085449 774.946045,516.485168 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M697.383850,680.960938 
	C698.151306,672.475769 704.488281,666.594421 713.067322,666.121887 
	C723.715576,665.535400 730.243896,670.170227 732.930786,680.626526 
	C731.604980,679.878784 729.995361,678.970276 729.094543,677.596863 
	C726.208069,673.195984 723.017944,669.483093 717.275085,668.973938 
	C710.454041,668.369080 704.976807,670.338257 701.281128,676.423828 
	C700.297241,678.044006 698.925354,679.428650 697.383850,680.960938 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M769.492065,680.955505 
	C770.296143,672.802673 776.672180,666.734009 784.766541,666.137268 
	C795.164612,665.370667 802.046997,669.835388 804.970703,679.629028 
	C803.597656,678.917908 801.744324,678.109436 800.980164,676.707336 
	C797.323853,669.998535 791.272827,668.445435 784.508179,669.098511 
	C779.096436,669.620850 775.442261,673.137512 772.848389,677.859253 
	C772.186218,679.064636 770.856506,679.903259 769.492065,680.955505 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M662.891602,673.095093 
	C658.503967,680.173157 653.031860,686.878052 649.173157,694.409485 
	C644.362488,703.798950 640.881775,713.869812 636.550171,723.889893 
	C636.696045,720.472229 636.489685,716.596741 637.652100,713.187866 
	C642.205994,699.832275 648.738770,687.491028 658.130005,676.827698 
	C659.387878,675.399536 661.048767,674.326294 662.891602,673.095093 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M732.713135,709.052002 
	C733.902832,709.960754 734.727356,711.748108 735.575134,711.759155 
	C746.294678,711.899109 757.017578,711.792358 767.739197,711.692017 
	C768.088501,711.688782 768.432190,711.085266 768.914124,710.381409 
	C771.018005,712.314209 770.185181,714.825684 767.035828,714.887207 
	C756.599243,715.091187 746.148743,715.161926 735.720154,714.803894 
	C732.977478,714.709717 729.481140,713.348694 732.713135,709.052002 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M770.778687,554.496094 
	C763.363892,554.528015 755.774170,554.098022 748.182800,554.065918 
	C743.073914,554.044250 737.962646,554.584961 732.424805,554.809387 
	C733.824829,553.496704 735.608643,551.253540 737.487610,551.170776 
	C746.212646,550.786499 754.973328,550.780518 763.699890,551.147827 
	C766.039673,551.246216 768.304138,553.136108 770.778687,554.496094 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M635.939697,778.186829 
	C640.145752,787.905945 643.903320,798.111755 648.358459,808.003296 
	C650.619995,813.024658 654.138977,817.479553 657.118774,822.541992 
	C655.413940,821.549255 653.275391,820.506592 652.011658,818.811707 
	C643.953735,808.003784 639.082336,795.674072 635.505249,782.796265 
	C635.146301,781.503784 635.667114,779.966980 635.939697,778.186829 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M560.917969,922.596191 
	C557.734863,924.011963 554.471375,925.052612 550.925232,926.183411 
	C548.197510,923.553467 547.192444,923.382385 537.486633,924.386414 
	C537.279724,928.706299 537.068542,933.114441 536.559326,937.829224 
	C535.945557,932.999756 535.557678,927.865601 535.417847,922.724731 
	C535.402649,922.166199 536.839417,921.043762 537.570557,921.070190 
	C545.329956,921.350464 553.082886,921.810547 560.917969,922.596191 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M781.470825,967.941284 
	C779.931213,966.627747 778.835815,965.354248 777.397888,964.072266 
	C778.161987,960.779297 780.066467,962.052368 780.917908,963.845886 
	C782.957092,968.141357 786.443420,965.289246 789.187195,965.679749 
	C789.556519,965.732300 790.887817,962.582031 790.914734,960.901001 
	C791.079590,950.610107 790.902710,940.313904 791.048279,930.022400 
	C791.098450,926.478149 790.014160,924.924561 786.228333,924.909851 
	C782.422302,924.895081 780.849792,926.069092 780.928101,930.064575 
	C781.061096,936.843262 780.726685,943.631165 780.287598,950.237244 
	C780.029602,941.416260 780.072754,932.773132 780.115845,924.130066 
	C783.881287,924.130066 787.646790,924.130066 792.028015,924.130066 
	C792.028015,934.072510 792.028015,943.625122 792.028076,953.177734 
	C792.028137,956.655518 792.171326,960.142334 791.953247,963.606323 
	C791.873779,964.869934 790.934692,966.079407 790.150024,967.638184 
	C787.246460,967.969055 784.580750,967.975220 781.470825,967.941284 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M732.204224,537.243713 
	C737.021423,536.603516 742.072388,536.041443 747.126831,536.007202 
	C754.536255,535.957153 761.948181,536.284973 769.695251,536.689453 
	C768.434326,537.616577 766.848267,538.870972 765.238281,538.902527 
	C755.681213,539.089844 746.117798,539.051086 736.558533,538.929199 
	C735.179932,538.911560 733.812256,538.036194 732.204224,537.243713 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M606.339661,960.857422 
	C611.166138,968.079590 619.028320,970.227722 627.359985,966.753052 
	C629.484558,965.867004 631.911865,964.100037 633.913208,967.610474 
	C624.176514,972.353882 614.628845,973.156067 605.768127,965.520569 
	C605.327637,965.140991 604.532593,965.172913 603.546875,965.007935 
	C603.147400,963.936584 603.103760,962.870544 603.507812,961.402039 
	C604.750244,960.952148 605.544922,960.904785 606.339661,960.857422 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M737.186035,617.212891 
	C746.418396,616.758606 755.883911,616.624023 765.677979,616.736816 
	C765.654358,617.319946 765.298889,617.951965 764.950317,617.948181 
	C755.772827,617.849731 746.596008,617.685425 737.186035,617.212891 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M680.588623,960.074585 
	C683.861816,967.330811 688.823608,969.783386 696.384460,967.654358 
	C698.588867,967.033691 700.807556,966.463501 703.390564,965.843750 
	C703.202942,967.015991 702.971313,968.785828 702.033875,969.322144 
	C695.193909,973.235779 685.126648,971.419495 680.098022,965.206665 
	C679.290588,964.209106 680.144165,961.867249 680.588623,960.074585 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M867.537964,763.989502 
	C867.114563,756.630005 867.073975,749.256653 867.094116,741.441772 
	C868.424133,742.290527 870.676208,743.501587 870.787659,744.885071 
	C871.207642,750.098816 871.108643,755.379761 870.779602,760.608887 
	C870.705505,761.786438 868.918945,762.856201 867.537964,763.989502 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M633.481079,739.083008 
	C633.884705,746.693970 633.925415,754.385437 633.904785,762.537964 
	C632.921326,762.210083 631.217163,761.436951 631.200867,760.629700 
	C631.071594,754.231934 631.205078,747.825562 631.432983,741.428040 
	C631.460571,740.654175 632.531067,739.917419 633.481079,739.083008 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M775.009277,537.569580 
	C781.809814,538.268616 788.625061,539.344482 795.731079,540.664001 
	C794.277832,541.532654 792.501953,542.751160 790.796021,542.660034 
	C787.161316,542.465820 783.502869,541.822021 779.974792,540.900513 
	C778.189148,540.434082 776.665833,538.963135 775.009277,537.569580 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M755.061768,575.603394 
	C761.122314,575.639160 767.245728,576.029907 773.696167,576.660095 
	C773.859619,577.357971 773.833008,578.050903 773.511230,578.239014 
	C769.915100,580.341309 758.326355,578.863464 755.061768,575.603394 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M728.203003,577.243042 
	C734.328369,576.317627 740.687927,575.705261 747.489380,575.161865 
	C741.904053,579.952393 735.348389,580.244507 728.203003,577.243042 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M1012.017090,941.997559 
	C1012.672791,942.790344 1013.882324,943.572815 1013.897278,944.377441 
	C1014.026550,951.353516 1013.964417,958.333801 1013.871704,965.311707 
	C1013.864990,965.818420 1013.171631,966.315979 1012.401245,966.440308 
	C1012.008972,958.041016 1012.013062,950.019287 1012.017090,941.997559 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M774.945557,596.410889 
	C770.875122,596.364868 766.746460,595.964050 762.290161,595.331055 
	C765.796265,591.662720 771.712830,592.100952 774.945557,596.410889 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M790.949097,518.511108 
	C786.929016,518.314758 782.857910,517.753113 778.394043,517.078979 
	C781.278076,513.621887 787.868042,514.224487 790.949097,518.511108 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M738.852539,594.748535 
	C735.297546,595.692871 731.555969,596.285095 727.403320,596.835266 
	C728.610474,592.469238 733.212585,591.524292 738.852539,594.748535 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M722.760620,516.822021 
	C719.322815,517.676575 715.570740,518.301941 711.409302,518.903809 
	C713.255188,514.341248 717.669617,513.459106 722.760620,516.822021 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M828.456543,776.212830 
	C828.377258,779.513916 827.995178,782.975769 827.367249,786.733459 
	C823.704529,784.173767 824.127502,779.808044 828.456543,776.212830 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M742.995117,867.982666 
	C743.307739,867.432068 743.638550,866.392822 743.930115,866.403687 
	C748.895569,866.589417 753.856079,866.905334 758.443970,867.592896 
	C753.045105,867.989746 748.020142,867.986206 742.995117,867.982666 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M717.038574,539.232300 
	C720.038757,538.257935 723.120300,537.682129 726.603882,537.153564 
	C725.435608,541.502502 721.931702,542.363831 717.038574,539.232300 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M726.943726,554.706909 
	C723.951050,555.704895 720.878296,556.313599 717.402466,556.889038 
	C719.279236,552.445923 721.999390,551.746033 726.943726,554.706909 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M784.948853,556.484436 
	C781.862610,556.414124 778.724243,555.984680 775.283447,555.317810 
	C777.861633,551.696350 781.831421,552.114685 784.948853,556.484436 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M867.073303,736.008179 
	C866.121094,733.322876 865.575867,730.560547 865.073608,727.399170 
	C869.623840,729.237793 870.278625,731.711914 867.073303,736.008179 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M1011.999146,941.719849 
	C1012.013062,950.019287 1012.008972,958.041016 1012.004883,966.531372 
	C1011.827820,966.997559 1011.650818,966.995117 1011.208374,966.980469 
	C1010.989075,958.301636 1011.035217,949.635010 1011.144653,940.508057 
	C1011.465759,940.512634 1011.723450,940.977356 1011.999146,941.719849 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M706.148804,541.266052 
	C708.716614,540.302002 711.460876,539.674194 714.602661,539.078247 
	C712.784546,543.599243 710.996643,544.112854 706.148804,541.266052 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M590.339966,966.948242 
	C590.092651,958.559631 590.099731,950.159363 590.197510,941.279114 
	C590.543030,949.185303 590.797791,957.571411 591.076050,966.413818 
	C590.931152,966.892273 590.762817,966.914429 590.339966,966.948242 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M778.050476,577.527466 
	C780.486389,577.595825 782.973938,578.017090 785.740112,578.679443 
	C783.310486,582.154663 781.143982,581.869995 778.050476,577.527466 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M768.998413,843.485229 
	C769.179443,843.017822 769.350464,843.016968 769.777039,843.037781 
	C770.015503,850.715454 769.998474,858.371582 769.993652,866.491760 
	C769.837769,866.977905 769.669739,866.999878 769.244751,866.639709 
	C768.960510,865.504456 768.933228,864.751404 768.905884,863.998291 
	C768.933411,857.316101 768.960938,850.633972 768.998413,843.485229 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M785.993896,598.439880 
	C783.525024,598.370667 781.042542,597.936218 778.276245,597.261108 
	C781.607300,593.943359 783.356384,594.174194 785.993896,598.439880 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M742.639526,867.988098 
	C748.020142,867.986206 753.045105,867.989746 758.537476,867.987488 
	C759.640198,868.048828 760.275574,868.115967 760.910889,868.183167 
	C760.908813,868.342041 760.906677,868.500854 760.904602,868.659729 
	C753.984741,868.659729 747.064941,868.659729 740.145142,868.659729 
	C740.128845,868.504883 740.112610,868.350037 740.096313,868.195129 
	C740.825500,868.127930 741.554688,868.060730 742.639526,867.988098 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M708.929993,518.655518 
	C706.587769,519.660645 704.174011,520.295349 701.380005,520.900269 
	C702.506287,516.625305 705.077209,515.615540 708.929993,518.655518 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M636.058594,657.287354 
	C638.619202,658.341675 641.158264,659.777100 643.910522,661.473877 
	C640.515015,663.231262 637.591858,661.761169 636.058594,657.287354 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M633.957886,767.156128 
	C634.755493,769.584167 635.382263,772.389771 635.925964,775.597290 
	C630.925415,774.205139 633.233154,770.656433 633.957886,767.156128 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M635.505676,726.057617 
	C635.527588,728.534973 635.179260,731.069153 634.563477,733.874512 
	C632.381042,731.239197 631.798035,728.471924 635.505676,726.057617 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M733.029297,868.025024 
	C732.845459,866.738831 732.860107,865.741394 732.930420,864.372009 
	C733.859863,865.345520 734.733582,866.691040 735.607361,868.036499 
	C734.814148,868.128967 734.020996,868.221375 733.029297,868.025024 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M661.165649,968.187378 
	C661.589905,967.141785 662.223389,966.373962 662.929688,965.303101 
	C663.596680,965.720032 664.190918,966.440125 664.785095,967.160278 
	C663.648376,967.595215 662.511658,968.030212 661.165649,968.187378 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M635.758423,644.088928 
	C635.630310,645.634705 635.158203,647.199585 634.397949,648.975586 
	C634.544739,647.493835 634.979553,645.800964 635.758423,644.088928 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M633.973450,652.124634 
	C634.751953,653.273621 635.388306,654.745178 636.068848,656.569092 
	C635.352356,655.430115 634.591858,653.938660 633.973450,652.124634 
z"/>
<path fill="#FFFEFF" opacity="1.000000" stroke="none" 
	d="
M663.345337,961.939636 
	C662.963196,960.931946 662.928162,959.863953 662.946228,958.397949 
	C665.772888,958.838867 665.676453,960.190430 663.345337,961.939636 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M768.570374,864.059570 
	C768.933228,864.751404 768.960510,865.504456 768.990662,866.634766 
	C768.993530,867.011902 769.002563,866.997314 768.674561,867.008789 
	C767.899719,867.022461 767.452820,867.024719 767.005981,867.026978 
	C767.415649,866.058289 767.825195,865.089600 768.570374,864.059570 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M866.715759,910.561829 
	C865.901428,910.825745 864.865417,910.873718 863.446106,910.849243 
	C864.206604,910.633179 865.350281,910.489563 866.715759,910.561829 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M766.861145,867.202393 
	C767.452820,867.024719 767.899719,867.022461 768.681824,867.006104 
	C768.250183,867.120605 767.483215,867.249268 766.861145,867.202393 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M680.279541,957.979248 
	C680.167175,957.128357 680.258911,956.289978 680.559570,955.213684 
	C680.841858,955.742126 680.915161,956.508484 680.938965,957.658691 
	C680.889404,958.042419 680.483582,957.991760 680.279541,957.979248 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M748.496216,515.861328 
	C750.174927,515.636047 752.277344,515.478149 754.650879,515.557373 
	C752.921326,515.839233 750.920654,515.883972 748.496216,515.861328 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M549.941040,943.468140 
	C550.658875,943.789734 551.874573,944.563965 551.893433,945.366455 
	C552.034241,951.348572 551.965149,957.336792 551.867188,963.321533 
	C551.858948,963.826904 551.160461,964.321045 550.384399,964.908813 
	C549.952271,957.976074 549.918518,950.954956 549.941040,943.468140 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M606.281616,960.408447 
	C605.544922,960.904785 604.750244,960.952148 603.560791,961.004517 
	C603.123108,956.976807 603.080078,952.944092 603.100830,948.450928 
	C603.956482,948.012085 604.748291,948.033691 605.938965,948.098083 
	C606.299744,952.080444 606.261658,956.019897 606.281616,960.408447 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M576.564941,941.184692 
	C576.784668,949.009094 576.755798,957.177002 576.491089,965.676880 
	C576.275513,957.848633 576.295898,949.688416 576.564941,941.184692 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M551.604126,936.955566 
	C551.531006,938.117859 551.170227,939.480347 550.448914,940.920898 
	C550.497742,939.717834 550.907104,938.436890 551.604126,936.955566 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M563.673584,939.708862 
	C563.414307,939.780579 563.231445,939.560242 563.090576,939.055847 
	C563.342529,938.972900 563.548340,939.187866 563.673584,939.708862 
z"/>
<path fill="#FFFBFF" opacity="1.000000" stroke="none" 
	d="
M873.115967,955.486084 
	C868.747498,955.617126 864.785034,955.617126 860.433289,955.617126 
	C860.433289,944.650513 860.433289,933.756226 860.433289,922.419800 
	C864.781555,922.419800 869.293762,921.804321 873.566956,922.552917 
	C881.519592,923.946167 886.139343,930.335938 886.201111,938.754700 
	C886.266296,947.626038 881.940735,953.289429 873.115967,955.486084 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M848.069092,966.440796 
	C847.756958,966.370728 847.111145,965.840698 847.113037,965.313110 
	C847.177673,947.406860 847.303772,929.500916 847.744629,911.333618 
	C848.057922,929.375549 848.047668,947.678772 848.069092,966.440796 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M702.955139,924.557495 
	C703.678406,923.739380 704.423950,923.372009 705.593872,923.428223 
	C706.599670,936.392334 709.062012,939.527283 721.294373,943.256042 
	C723.995667,944.079468 726.740723,944.769714 729.411804,945.679321 
	C732.690186,946.795776 736.852417,947.612793 735.874573,952.363281 
	C734.924561,956.978394 730.798279,956.973022 727.051697,956.902954 
	C725.905457,956.881592 724.680176,956.752869 723.636902,956.321045 
	C719.417419,954.574463 715.254639,952.690552 710.879272,950.773254 
	C708.893677,953.130371 706.759583,955.663818 704.599060,958.228638 
	C705.102417,959.443359 705.590881,960.622070 706.079346,961.800720 
	C705.451172,962.209045 704.822998,962.617371 703.792664,963.053345 
	C703.112122,960.819092 702.833679,958.557129 702.531921,956.105042 
	C700.670288,956.413635 698.842407,957.281738 697.506653,956.796143 
	C695.804016,956.177185 693.326355,954.552551 693.224426,953.206726 
	C692.763550,947.122559 693.022949,940.983826 693.022949,934.535034 
	C696.416931,934.333313 699.344238,934.159302 702.977417,933.943298 
	C702.977417,930.872620 702.977417,927.940369 702.955139,924.557495 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M678.974915,934.608948 
	C678.680359,934.859558 678.183411,934.926636 677.310059,934.968994 
	C677.546570,934.771301 678.159546,934.598328 678.974915,934.608948 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M680.759155,935.811768 
	C680.481384,935.898438 680.279907,935.671814 680.090210,935.158997 
	C680.276855,935.143311 680.521362,935.351868 680.759155,935.811768 
z"/>
<path fill="#FEFAFF" opacity="1.000000" stroke="none" 
	d="
M828.323914,953.068604 
	C823.730042,957.496521 818.633789,958.392273 814.618835,955.746277 
	C810.540283,953.058289 808.667542,946.184265 810.570740,940.887634 
	C812.479797,935.574585 816.247009,933.240295 821.769043,933.948792 
	C826.889771,934.605896 830.316772,938.887573 830.169128,945.094238 
	C830.108154,947.659424 829.132507,950.202942 828.323914,953.068604 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M798.058533,966.443481 
	C797.747498,966.373291 797.104126,965.843445 797.105469,965.315125 
	C797.151978,946.745789 797.259094,928.176636 797.647095,909.312683 
	C797.966675,928.006775 797.996704,946.995667 798.058533,966.443481 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M810.072998,924.369873 
	C811.537842,924.332031 812.939148,924.666748 814.709351,925.017578 
	C813.620605,926.228821 812.163147,927.423950 810.214478,929.021851 
	C810.125732,927.168213 810.067627,925.955322 810.072998,924.369873 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M919.210083,959.120605 
	C915.558533,958.939148 912.718750,957.355713 913.375488,954.125366 
	C913.816956,951.953918 916.533875,950.073792 918.558533,948.476196 
	C919.421143,947.795532 921.154175,947.918884 922.423645,948.115784 
	C924.985535,948.513123 928.587097,947.607178 929.032043,951.569702 
	C929.500244,955.739624 926.830933,957.938049 923.100281,958.928162 
	C922.003662,959.219177 920.791626,959.075073 919.210083,959.120605 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M947.946533,936.619141 
	C945.719360,936.737488 943.231079,933.363892 941.236206,937.828857 
	C940.396973,936.567383 939.830627,935.119263 939.207947,933.330933 
	C939.829346,932.993713 940.507080,932.996582 941.523682,932.998657 
	C943.907166,934.077209 945.951904,935.156494 947.946533,936.619141 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M620.824219,958.788025 
	C619.490601,956.742676 617.309326,954.278748 617.864075,953.043701 
	C618.780090,951.004150 621.312317,949.490173 623.461182,948.286011 
	C624.600220,947.647705 626.518738,947.717102 627.814636,948.197327 
	C629.908325,948.973328 631.778687,950.351990 633.743408,951.476074 
	C632.166626,953.791931 631.051331,956.849426 628.875916,958.202942 
	C626.925232,959.416565 623.808228,958.755554 620.824219,958.788025 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M645.140503,940.526306 
	C645.322266,948.855225 645.442566,957.642883 645.244629,966.704956 
	C644.977295,958.314575 645.028198,949.649841 645.140503,940.526306 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M983.720520,955.470215 
	C983.020386,951.176208 985.399780,948.825928 988.676086,948.541077 
	C992.100769,948.243286 995.650085,949.378540 999.144897,949.887329 
	C998.654236,952.145325 998.817444,954.907227 997.542847,956.561035 
	C994.472107,960.545349 987.172791,959.906433 983.720520,955.470215 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M973.967590,962.592529 
	C972.866638,963.513123 971.748169,964.051697 970.367615,964.795166 
	C970.072266,962.310120 970.039001,959.620178 970.001587,956.483215 
	C970.478760,954.518188 970.960144,953.000244 971.687012,951.252075 
	C972.604980,954.751404 973.277527,958.480957 973.967590,962.592529 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M779.783813,924.374390 
	C780.072754,932.773132 780.029602,941.416260 779.986755,950.527588 
	C779.987000,950.995911 779.575134,951.081726 779.372009,951.133911 
	C779.263184,942.330261 779.357483,933.474487 779.783813,924.374390 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M752.975586,955.616211 
	C752.745056,955.134033 752.553223,954.273254 752.557007,953.196594 
	C752.839783,953.733032 752.927063,954.485352 752.975586,955.616211 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M776.837158,964.114624 
	C776.997986,964.390747 776.817749,964.650024 776.269287,964.835571 
	C776.146912,964.557922 776.345276,964.336548 776.837158,964.114624 
z"/>
<path fill="#FEF5FF" opacity="1.000000" stroke="none" 
	d="
M765.258545,951.290283 
	C765.541565,951.207092 765.744202,951.446838 765.883728,952.008179 
	C765.609558,952.092834 765.395447,951.854004 765.258545,951.290283 
z"/>
<path fill="#F4C6FB" opacity="1.000000" stroke="none" 
	d="
M970.257996,925.463501 
	C970.590088,927.115662 970.804138,929.209595 971.030579,931.302124 
	C971.328125,934.051575 970.137085,935.449158 967.358398,935.006042 
	C961.773254,934.115234 960.528748,936.914185 960.947937,941.822876 
	C961.269165,945.585571 960.979187,949.400330 960.895691,953.670166 
	C960.581116,948.113098 960.242188,942.078308 960.211731,936.041931 
	C960.208435,935.388062 961.928955,934.303284 962.955994,934.155334 
	C965.025940,933.857178 967.168945,934.066650 969.775452,934.066650 
	C969.912292,931.049072 970.028931,928.476868 970.257996,925.463501 
z"/>
<path fill="#C972E2" opacity="1.000000" stroke="none" 
	d="
M706.434021,961.883179 
	C705.590881,960.622070 705.102417,959.443359 704.599060,958.228638 
	C706.759583,955.663818 708.893677,953.130371 710.879272,950.773254 
	C715.254639,952.690552 719.417419,954.574463 723.636902,956.321045 
	C724.680176,956.752869 725.905457,956.881592 727.051697,956.902954 
	C730.798279,956.973022 734.924561,956.978394 735.874573,952.363281 
	C736.852417,947.612793 732.690186,946.795776 729.411804,945.679321 
	C726.740723,944.769714 723.995667,944.079468 721.294373,943.256042 
	C709.062012,939.527283 706.599670,936.392334 705.927979,923.423218 
	C713.293457,907.950684 731.457825,905.270813 746.336670,917.414185 
	C745.114624,919.408203 743.819275,921.452576 742.594421,923.538330 
	C741.133118,926.026733 739.483948,926.040039 736.781494,925.049866 
	C732.709717,923.557922 728.338806,922.612549 724.022034,922.202271 
	C722.464355,922.054260 719.336548,924.106506 719.326294,925.177856 
	C719.308472,927.026001 720.735596,929.849060 722.324097,930.568726 
	C726.802063,932.597290 731.655701,933.811829 736.387085,935.263000 
	C750.439148,939.572937 751.609741,953.634216 744.057373,962.000061 
	C739.321960,967.245667 732.214783,969.183228 725.618530,968.345825 
	C719.175781,967.527893 713.052307,964.194580 706.434021,961.883179 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M967.866028,952.019409 
	C966.569519,952.408508 964.559570,952.618042 964.544800,952.521667 
	C963.941223,948.583557 963.062378,944.587524 963.323242,940.672546 
	C963.411438,939.348389 966.351990,938.054871 968.164795,937.071228 
	C969.094788,936.566650 970.428894,936.600342 971.549500,936.708862 
	C972.806519,936.830566 974.931641,937.085571 975.080872,937.682434 
	C975.406982,938.986084 975.076538,940.747314 974.380554,941.957031 
	C972.455261,945.303772 970.204956,948.463501 967.866028,952.019409 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
